import React from 'react'
import apple from '../images/crossplatform/apple.png'
import playStore from '../images/crossplatform/google.png'

function CrossPlatform() {
    return (
        <div className='py-16'>
            <h2 className='text-3xl font-bold text-center'>Available Cross-Platform</h2>
            <p className='px-2 py-1 text-center font-medium text-gray-600'>Disconnect from desktop and reply to messages on mobile</p>
            <div className='flex justify-center space-x-3 mt-4'>
                <a href="https://apps.apple.com/au/app/chatbot-live-chat/id1627218767" target="_blank" rel="noreferrer" > <img src={apple} alt="xbot-on-ios" className='w-40' /></a>
                <a href="https://play.google.com/store/apps/details?id=com.dfktv2.chatbot" target="_blank" rel="noreferrer" >  <img src={playStore} alt="xbot-on-andorid" className='w-40' /> </a>

            </div>
        </div>
    )
}

export default CrossPlatform