import React from 'react'
import HeroImg from './../EducationBot/media/hero.png'
import WhatsappHeroSlider from '../InnerTemplate/WhatsappHeroSlider'
import WhyDoYouNeed from '../InnerTemplate/WhyDoYouNeed'
import WhyImg from './../WhatsappChatbot/image/test1.png'
import Cta from '../InnerTemplate/Cta'
import WhatsappSolution from '../InnerTemplate/WhatsappSolution'
import sol1Img from './../EducationBot/media/sol1.png'
import sol2Img from './../EducationBot/media/sol2.png'
import sol3Img from './../EducationBot/media/sol3.png'
import sol4Img from './../EducationBot/media/sol4.png'
import Clients from '../Clients'
import CrossPlatform from '../CrossPlatform'
import Pricing from '../Pricing'
import { Helmet } from 'react-helmet'

function EduBot() {
    return (
        <div className="pt-10">
            <Helmet>‍
                <title>Boost Your Edtech Business with a WhatsApp Chatbot | Xbot by Webspecia</title>‍
                <meta name="description" content="Are you an edtech business owner looking to increase student engagement and enrollment? A WhatsApp chatbot can provide fast, automated responses to common student questions, facilitate course enrollment and payment, and provide personalized feedback and resources. Learn how a WhatsApp chatbot can improve your student experience and drive growth for your edtech business." />

            </Helmet>

            <div>
                <WhatsappHeroSlider
                    heroimg={HeroImg}
                    HeroHeading={'5x your student enrollments with our Edtech chatbot'}
                />
            </div>


            <div >
                <Clients title={`Try us, you're in good company`} />
            </div>
            <div>
                <WhyDoYouNeed
                    heading={'Why your Edtech brand needs an AI chatbot?'}
                    fOneHeading={'55%'}
                    fOneText={'Average growth in conversions on marketing campaigns'}
                    fTwoHeading={'37%'}
                    fTwoText={'Costs saved by automating customer support operations'}
                    fThreeHeading={'49%'}
                    fThreeText={'Businesses have already started running marketing campaigns through chatbots'}
                    fFourHeading={'41%'}
                    fFourText={'Brands use conversational marketing for sales automation'}
                    WhyImg={WhyImg}
                />
            </div>

            <div >
                <Cta />
            </div>
            <div>
                <WhatsappSolution
                    heading={`How can Xbot grow your Edtech brand?`}
                    sol1Title={`Streamline your admission process & enhance student experience`}
                    sol1Desc={`Xbot’s AI powered chatbots can help universities & educational institutions to engage prospective students, drive admissions. Thus leading to quicker & hassle free processes.`}
                    sol1Img={sol1Img}

                    sol2Title={`24/7 Student Support`}
                    sol2Desc={`Round the clock support to your students. Take care of enrollments regarding enrollment, admissions etc`}
                    sol2Img={sol2Img}

                    sol3Title={`Resolve 3x student inquiries and get more enrollments`}
                    sol3Desc={`All student inquiries can be resolved on a chatbot.  With Xbot, resolve queries faster and ensure more enrollments`}
                    sol3Img={sol3Img}

                    sol4Title={`Schedule 1-on-1 sessions of teachers & students for query resolution`}
                    sol4Desc={`Xbot Chatbot enables you to schedule live sessions between teachers and students to solve complex queries.`}
                    sol4Img={sol4Img}
                />
            </div>
            <div name="pricing" >
                <Pricing />
            </div>
            <div className=''>
                <Cta />
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />

            </div>

        </div>
    )
}

export default EduBot
