import React from 'react'
import xbotPromo from '../xbot-promo.mp4'
import Integration from './Integration'
import Features from './Features'
import Pricing from './Pricing'
import CrossPlatform from './CrossPlatform'
import Seamless from './Seamless'
import IntegrateWithCrm from './IntegrateWithCrm'
import Clients from './Clients'
import Faq from './Faq'
import HeroSlider from './HeroSlider'
import DemoForm from './DemoForm'
import { Helmet } from 'react-helmet'



function Home() {
    document.title = "Xbot-#1 AI Chatbot Builder, WhatsApp Business Bot";
    return (
        <div className=' '>

            <Helmet>‍
                <title>Xbot-#1: Build and Integrate Custom WhatsApp Chatbots for Your Business</title>‍
                <meta name="description" content="Xbot by Webspecia is a powerful SAS platform that allows businesses to build and deploy custom chatbots on WhatsApp. With Xbot, you can easily create and manage chatbots that can handle customer inquiries, facilitate orders, and provide personalized recommendations. Whether you're a small business owner or a large enterprise, Xbot has a range of features and tools that can help you improve customer engagement and boost sales. Try Xbot today and see how it can transform your business's WhatsApp communication strategy." />

            </Helmet>


            <div>
                <HeroSlider />
            </div>




            <div name="features" className="bg-gray-100 md:py-10">
                <Features />
            </div>
            <div className=' py-10' >
                <h2 className='text-3xl font-bold text-center mb-8 md:mb-2'>Seamless WhatsApp Integration</h2>
                <div className="grid md:grid-cols-2 ">
                    <div className='px-10 md:px-2 md:pl-36 md:py-6'>
                        <Seamless />
                    </div>
                    <div className='pt-6 px-10 md:px-20 md:py-10'>
                        <div className="p-2 border border-gray-200 rounded-lg shadow-md shadow-gray-300">
                            <video className="w-full" controls>
                                <source src={xbotPromo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>

            </div>
            <div name="integration" className='bg-gray-100 py-3 md:py-12'>
                <IntegrateWithCrm />
                <p className='mt-20 px-2 text-center text-lg'>Enjoy seamless integrations with your favorite e-stores, CRMs and applications</p>
                <Integration />


            </div>
            <div name="pricing" >
                <Pricing />
            </div>
            <div className=' '>
                <Clients title={`100+ Brands. 5x Growth. 1Mn+ Conversations`} />
            </div>
            <div className=' md:grid md:grid-cols-2 '>
                <div name="faqs" className=' py-3 md:py-12'>
                    <Faq />
                </div>

                <div name="demo" className=' pt-20   '>
                    <h2 className='text-3xl font-medium text-blue-800 text-center md:mt-14'>Request a Demo!</h2>
                    <p className='text-center text-sm pt-1'>Get started on Xbot with the help of a personalised demo.</p>
                    <DemoForm />
                </div>
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />
            </div>




        </div >
    )
}

export default Home
