import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './components/Home';
import HomeIndia from './components/HomeIndia';
import XbotVsWati from './components/XbotVsWati';
import Footer from './components/Footer';
import XbotVsYellow from './components/XbotVsYellow';
import XbotVsHaptic from './components/XbotVsHaptic';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Privacy from './components/Privacy';
import TermsCondition from './components/TermsCondition';
import Navbar2 from './components/Navbar2';
import WhatsappChatBot from './components/WhatsappChatbot/WhatsappChatBot';
import EcomBot from './components/EcommerceBot/EcomBot';
import LogisticsBot from './components/LogisticsBot/LogisticsBot';
import EduBot from './components/EducationBot/EduBot';
import TravelBot from './components/TravelBot/TravelBot';
import Iptest from './components/Iptest';
import InsuranceBot from './components/InsuranceBot/InsuranceBot';
import GovtBot from './components/GovtBot/GovtBot';
import RealEstate from './components/RealEstate/RealEstate';
import InstagramBot from './components/InstagramBot/InstagramBot';
import PricingPage from './components/PricingPage';
import Test from './components/Test';
import NotFound from './components/NotFound';

function App() {
  return (
    <BrowserRouter>
      <>

        <div className='mt-14'>
          <Routes>



            <Route exact path="/" element={<> <Navbar /><Home /></>} />
            <Route exact path="in" element={<><Navbar /><HomeIndia /></>} />
            <Route exact path="whatsapp" element={<><Navbar /><WhatsappChatBot /></>} />
            <Route exact path="ecommerce" element={<><Navbar /><EcomBot /></>} />
            <Route exact path="logistics" element={<><Navbar /><LogisticsBot /></>} />
            <Route exact path="education" element={<><Navbar /><EduBot /></>} />
            <Route exact path="travel" element={<><Navbar /><TravelBot /></>} />
            <Route exact path="insurance" element={<><Navbar /><InsuranceBot /></>} />
            <Route exact path="government" element={<><Navbar /><GovtBot /></>} />
            <Route exact path="realestate" element={<><Navbar /><RealEstate /></>} />
            <Route exact path="instagram-facebook" element={<><Navbar /><InstagramBot /></>} />
            <Route exact path="pricing" element={<><Navbar /><PricingPage /></>} />
            <Route exact path="iptest" element={<><Navbar /><Iptest /></>} />
            <Route exact path="test" element={<><Navbar /><Test /></>} />

            <Route exact path="xbot-vs-wati" element={<> <Navbar2 /><XbotVsWati /></>} />
            <Route exact path="xbot-vs-yellow-ai" element={<><Navbar2 /><XbotVsYellow /></>} />
            <Route exact path="xbot-vs-haptic" element={<><Navbar2 /><XbotVsHaptic /></>} />
            <Route exact path="about-us" element={<><Navbar2 /><AboutUs /></>} />
            <Route exact path="contact-us" element={<><Navbar2 /><ContactUs /></>} />
            <Route exact path="privacy-policy" element={<><Navbar2 /><Privacy /></>} />
            <Route exact path="tos" element={<><Navbar2 /><TermsCondition /></>} />
            <Route path='*' element={<><Navbar /><NotFound /></>} />

            {/* <Route exact path="About" element={<><Alerts /></>} /> */}
            {/* <Route exact path="Portfolio" element={<><Alerts /></>} /> */}

          </Routes>
        </div>
        <Footer />
        {/* <Tests /> */}

      </>
    </BrowserRouter >
  );
}

export default App;
