import React from 'react'
import Cta from '../InnerTemplate/Cta'
import WhatsappHeroSlider from '../InnerTemplate/WhatsappHeroSlider'
import WhyDoYouNeed from '../InnerTemplate/WhyDoYouNeed'
import HeroImg from './../LogisticsBot/media/hero.png'
import WhyImg from './../WhatsappChatbot/image/test1.png'
import sol1Img from './../LogisticsBot/media/sol1.png'
import sol2Img from './../LogisticsBot/media/sol2.png'
import sol3Img from './../LogisticsBot/media/sol3.png'
import sol4Img from './../LogisticsBot/media/sol4.png'
import WhatsappSolution from '../InnerTemplate/WhatsappSolution'
import Clients from '../Clients'
import CrossPlatform from '../CrossPlatform'
import Pricing from '../Pricing'
import { Helmet } from 'react-helmet'

function LogisticsBot() {
    return (
        <div className="pt-10">
            <Helmet>‍
                <title> Logistics Business - WhatsApp Chatbots</title>‍
                <meta name="description" content="Discover how integrating a WhatsApp chatbot into your logistics business can improve customer service, streamline operations, and increase sales. Explore the benefits and features of using a chatbot to manage customer inquiries, facilitate order tracking and updates, and provide shipping information. Find out how a WhatsApp chatbot can help you grow your business and achieve success." />

            </Helmet>
            <div>
                <WhatsappHeroSlider
                    heroimg={HeroImg}
                    HeroHeading={'Fix your supply chain with a logistics chatbot'}
                />
            </div>

            <div >
                <Clients title={`Try us, you're in good company`} />
            </div>
            <div>
                <WhyDoYouNeed
                    heading={'Why your Logistics business needs an AI chatbot?'}
                    fOneHeading={'94%'}
                    fOneText={'Reduction in overhead costs avoided with the use of automation bots'}
                    fTwoHeading={'79%'}
                    fTwoText={'Post-purchase queries can be answered by the bot'}
                    fThreeHeading={'88%'}
                    fThreeText={'Improvement in response time'}
                    fFourHeading={'35%'}
                    fFourText={'Increase in customer engagement across various channels'}
                    WhyImg={WhyImg}
                />
            </div>
            <div >
                <Cta />
            </div>
            <div>
                <WhatsappSolution
                    heading={`How can Xbot grow your Logistics business?`}
                    sol1Title={`Omnichannel Logistics Service`}
                    sol1Desc={`Let customers schedule and track shipments via WhatsApp, Facebook messenger, Instagram and more.`}
                    sol1Img={sol1Img}

                    sol2Title={`Communicate across borders`}
                    sol2Desc={`Engage all your customers across all your regions in their own language with AI - powered chatbots.`}
                    sol2Img={sol2Img}

                    sol3Title={`Automate notifications on WhatsApp`}
                    sol3Desc={`Deliver payments & shipment notifications automatically over to your customers favorite chat channel- WhatsApp`}
                    sol3Img={sol3Img}

                    sol4Title={`Handle complex support queries with simple solutions via a Logistics chatbot`}
                    sol4Desc={`Deliver personalised advice by empowering agents with a fully-integrated Logistics customer support chatbot, serving as a virtual advisor.`}
                    sol4Img={sol4Img}
                />
            </div>
            <div name="pricing" >
                <Pricing />
            </div>
            <div className=''>
                <Cta />
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />

            </div>
        </div>
    )
}

export default LogisticsBot
