import React, { useState } from 'react'
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';

function Faq() {
    const myData = [
        {
            id: 1,
            title: 'Do I need a chatbot? How can It help my business?',
            info: `Chatbot can help every business to automate marketing, generate sales & qualify leads. Chatbot can chat with your customer in real-time, turn them into prospects, qualify leads and grow sales at no cost.`

        },
        {
            id: 2,
            title: 'How can I get started?',
            info: 'We offer you to get started just at $5/Mo, where you will get 14 Days access to all the pro features , you can get access for the Facebook Messenger, Instagram & Telegram channel. Click “get started for Now” to register yourself with basic plan.'
        },
        {
            id: 3,
            title: 'How many channels do you support?',
            info: 'Currently we support you to build chatbot in Facebook Messenger, Whatsapp, Google Business Messenger, SMS, Voice, Telegram, Slack, Wechat, Instagram, Line, Viber. More channels will be added in the future.'
        },
        {
            id: 4,
            title: 'How Secure is my data?',
            info: 'We store all the conversations on our servers. They are scalable, secure and highly available.'
        },

    ];

    const [selected, setSelected] = useState(1)

    const toggle = (id) => {
        if (selected === id) {
            return setSelected(null)
        }
        setSelected(id)
        console.log(selected, 'is selected')
    }

    return (
        <>
            <div className='px-3 md:pl-16 mt-7 md:mt-0'>
                <h2 className='text-3xl font-bold text-center pb-10'>Frequently Asked Questions</h2>
                {myData.map(({ id, title, info }) =>
                    <div key={id}>
                        <h2 onClick={() => toggle(id)} className={`${selected !== id ? 'bg-white text-gray-900' : 'bg-gray-900 text-white'} ${id === 1 ? 'rounded-t-lg' : ''}  flex flex-row justify-between cursor-pointer w-full p-5 font-medium text-left text-gray-900 border  ${id === 4 && selected !== 4 ? 'border-gray-200 rounded-b-lg' : 'border-b-0 border-gray-200'}   focus:ring-4 focus:ring-gray-200 hover:bg-blue-900 hover:text-white`}>
                            <span>{title}</span>
                            <div className=''>
                                {selected !== id ? <AiOutlineCaretDown size={20} /> : <AiOutlineCaretUp size={20} />}
                            </div>
                        </h2>
                        {selected === id && <div>
                            <div className={`p-5 font-light border border-gray-200 ${id === 4 ? 'rounded-b-lg ' : 'border-b-0'}`}>
                                <p className="mb-2 text-gray-800">{info}</p>

                            </div>
                        </div>}
                    </div>

                )}
            </div>
        </>
    )
}

export default Faq