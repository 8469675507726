import React, { useEffect, useState } from 'react'
import Clients from './Clients'
import CrossPlatform from './CrossPlatform'
import Cta from './InnerTemplate/Cta'
import WhatsappHeroSlider from './InnerTemplate/WhatsappHeroSlider'
import Pricing from './Pricing'
import HeroImg from './../xbot-hero.gif'
import { Helmet } from 'react-helmet'


function PricingPage() {

    const [details, setDetails] = useState(null);

    useEffect(() => {
        fetch(
            "https://geolocation-db.com/json/89eb4d70-4cbe-11ed-a0f2-51b843ebe8d7"
        )
            .then(response => response.json())
            .then(data => setDetails(data));


    },


        [])



    return (
        <div className="pt-10">
            <Helmet>‍
                <title>Pricing | Xbot by Webspecia</title>‍
                <meta name="description" content="Looking for affordable and flexible pricing for your WhatsApp chatbot? Xbot by Webspecia offers a range of pricing plans to suit the needs of businesses of all sizes. From our basic plan with essential features to our premium plan with advanced automation and integrations, we have a solution that will fit your budget and requirements. Contact us today to learn more about our pricing and start using a WhatsApp chatbot to grow your business." />

            </Helmet>
            <div>
                <WhatsappHeroSlider
                    heroimg={HeroImg}
                    HeroHeading={`Get the best chatbot pricing with Xbot at ${details && details.country_code === 'IN' ? '₹399' : '$5'} a month*`}
                />
            </div>


            <div >
                <Clients title={`Try us, you're in good company`} />
            </div>


            <div name="pricing" >
                <Pricing />
            </div>
            <div className=''>
                <Cta />
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />

            </div>

        </div>
    )
}

export default PricingPage
