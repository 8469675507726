import React from 'react'
import HeroImg from './../RealEstate/media/hero.png'
import WhatsappHeroSlider from '../InnerTemplate/WhatsappHeroSlider'
import WhyDoYouNeed from '../InnerTemplate/WhyDoYouNeed'
import WhyImg from './../WhatsappChatbot/image/test1.png'
import Cta from '../InnerTemplate/Cta'
import WhatsappSolution from '../InnerTemplate/WhatsappSolution'
import sol1Img from './../RealEstate/media/sol1.png'
import sol2Img from './../RealEstate/media/sol2.png'
import sol3Img from './../RealEstate/media/sol3.png'
import sol4Img from './../RealEstate/media/sol4.png'
import Clients from '../Clients'
import CrossPlatform from '../CrossPlatform'
import Pricing from '../Pricing'
import { Helmet } from 'react-helmet'


function RealEstate() {
    return (
        <div className="pt-10">
            <Helmet>‍
                <title>Real Estate - WhatsApp Chatbot | Xbot by Webspecia</title>‍
                <meta name="description" content="Looking to increase your real estate sales? A WhatsApp Chatbot can help you streamline your operations, provide fast and personalized support to your customers, and generate leads. Xbot by Webspecia offers a range of features and integrations to make your chatbot work for you. Try it now and see the results for yourself." />

            </Helmet>
            <div>
                <WhatsappHeroSlider
                    heroimg={HeroImg}
                    HeroHeading={'Identify better prospects & close more deals'}
                />
            </div>


            <div >
                <Clients title={`Try us, you're in good company`} />
            </div>
            <div>
                <WhyDoYouNeed
                    heading={'Why your Real Estate business needs an AI chatbot?'}
                    fOneHeading={'55%'}
                    fOneText={'Brands that use chatbots generated more high-quality leads'}
                    fTwoHeading={'37%'}
                    fTwoText={'Costs saved by automating customer support operations'}
                    fThreeHeading={'49%'}
                    fThreeText={'Businesses have already started running marketing campaigns through chatbots'}
                    fFourHeading={'41%'}
                    fFourText={'Brands use conversational marketing for sales automation'}
                    WhyImg={WhyImg}
                />
            </div>

            <div >
                <Cta />
            </div>
            <div>
                <WhatsappSolution
                    heading={`How can Xbot grow your Real Estate business?`}
                    sol1Title={`Find real prospects from the stack of leads`}
                    sol1Desc={`Ease your hassle, by identifying genuine leads and targeting them particularly.`}
                    sol1Img={sol1Img}

                    sol2Title={`Broadcast new offers & schedule property viewings`}
                    sol2Desc={`Introduce new offers targeting your prospects. Use the bot to schedule property viewing appointments.`}
                    sol2Img={sol2Img}

                    sol3Title={`All property related FAQs, answered instantly by the chatbot`}
                    sol3Desc={`Avoid delayed responses, automate instant replies to resolve customer queries.`}
                    sol3Img={sol3Img}

                    sol4Title={`Offer live chat to prospects for complex queries`}
                    sol4Desc={`Let your prospects connect with agents to answer complex queries and provide in depth information.`}
                    sol4Img={sol4Img}
                />
            </div>
            <div name="pricing" >
                <Pricing />
            </div>
            <div className=''>
                <Cta />
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />

            </div>

        </div>
    )
}

export default RealEstate
