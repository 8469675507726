import React from 'react'
import userGirl from '../flow-one-img/user-girl.jpg'
import { GoArrowSmallDown, GoArrowSmallRight } from 'react-icons/go'
import landingPage from '../flow-one-img/landing-page.png'
import conversation from '../flow-one-img/conversation.png'
import crm from '../flow-one-img/crm.png'
import crmNotify from '../flow-one-img/crm-notify.png'
import crmUpdated from '../flow-one-img/crm-updated.png'
import emailCamp from '../flow-one-img/email-campaign.png'
import junk from '../flow-one-img/junk.png'
import noResp from '../flow-one-img/no-response.png'
import ppc from '../flow-one-img/ppc-ads.png'
import response from '../flow-one-img/response.png'
import social from '../flow-one-img/social-media.png'
import ReactTooltip from 'react-tooltip';

function IntegrateWithCrm() {
    return (
        <div className='md:px-36'>
            <h2 className='py-2 mb-10 text-3xl font-bold text-center '>Integrate With Any CRM</h2>
            <div className='grid grid-cols-6 h-auto justify-center px-2 md:px-0 '>
                {/* col-1 */}
                <div className=' flex items-center m-auto'>
                    <div data-tip data-for='user-entered'>
                        <img src={userGirl} alt="Bordered-Avatar" className="p-0.5 md:p-1  h-9 w-10 md:h-24 md:w-44 rounded-full ring-2 ring-gray-300 hover:border-2 hover:border-blue-800" />
                        <div className='text-center text-[10px] md:text-xs py-2 z-10 absolute px-8'>User</div>
                    </div>
                    <hr className='w-8 md:w-32 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                    <GoArrowSmallRight className='animate-myAnimation text-gray-700 -ml-0.5 w-5 h-5' />
                </div>
                <ReactTooltip id='user-entered' type='info' effect='solid'>
                    <div>User's journey starts</div>
                </ReactTooltip>
                {/* col-2 */}
                <div className=' flex flex-col justify-between  border-l border-r border-dashed border-gray-800'>
                    <div className='-mt-3 md:-mt-6 my-2' data-tip data-for='landing-page'>
                        <ReactTooltip id='landing-page' type='info' effect='solid'>
                            <span>User's comes from a landing page</span>
                        </ReactTooltip>
                        <div className=' flex items-center z-10 '>
                            <hr className=' w-10 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                            <GoArrowSmallRight className='animate-myAnimation  text-gray-700 -ml-0.5' />
                            <div className='mt-0 inline-flex relative items-center'>
                                <img src={landingPage} alt="landing-page" className=' w-16 grayscale hover:grayscale-0 ' />
                                <div className="animate-ping opacity-75 inline-flex absolute -top-2 -right-2 justify-center items-center w-3.5 h-3.5 text-[10px] md:w-6 md:h-6 md:text-xs  text-white bg-red-500 rounded-full border-2  dark:border-gray-900">1</div>
                                <div className=" inline-flex absolute -top-2 -right-2 justify-center items-center w-3.5 h-3.5 text-[10px] md:w-6 md:h-6 md:text-xs text-white bg-red-500 rounded-full border-2  dark:border-gray-900">1</div>
                            </div>
                            <hr className='ml-2 w-14 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                        </div>
                        <div className='text-center  text-[10px] md:text-[10px] md:text-xs py-2 '>Landing Page / Website</div>
                    </div>
                    <div className='my-2' data-tip data-for='social-media'>
                        <ReactTooltip id='social-media' place='bottom' type='info' effect='solid'>
                            <div className='p-1 w-44 text-center'>User's comes from social media</div>
                        </ReactTooltip>
                        <div className='flex items-center '>
                            <hr className='w-10 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                            <GoArrowSmallRight className='animate-myAnimation text-gray-700 -ml-0.5' />
                            <div className=''>
                                <img src={social} alt="landing-page" className=' z-10 w-16 grayscale hover:grayscale-0' />
                            </div>
                            <hr className='ml-2 w-14 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                        </div>
                        <div className='text-center text-[10px] md:text-xs py-2 '>Social Media</div>
                    </div>
                    <div className='my-2' data-tip data-for='ppc'>
                        <ReactTooltip id='ppc' place='bottom' type='info' effect='solid'>
                            <div className='p-1 w-44 text-center'>User's comes from pay per click Ads</div>
                        </ReactTooltip>
                        <div className='flex items-center'>
                            <hr className='w-10 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                            <GoArrowSmallRight className='animate-myAnimation text-gray-700 -ml-0.5' />
                            <div className=''>
                                <img src={ppc} alt="landing-page" className='w-16 grayscale hover:grayscale-0' />
                            </div>
                            <hr className='ml-2 w-14 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                        </div>
                        <div className='text-center text-[10px] md:text-xs py-2 '>PPC Ads</div>
                    </div>
                    <div>
                        <div className='-mb-16 my-2' data-tip data-for='email-camp'>
                            <ReactTooltip id='email-camp' place='bottom' type='info' effect='solid'>
                                <div className='p-1 w-44 text-center'>User's comes from Email Campaign</div>
                            </ReactTooltip>
                            <div className=' flex items-center  z-20 bg-red-0'>
                                <hr className=' w-10 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                                <GoArrowSmallRight className='animate-myAnimation  text-gray-700 -ml-0.5' />
                                <div className='inline-flex relative items-center'>
                                    <img src={emailCamp} alt="landing-page" className='w-16 grayscale hover:grayscale-0' />
                                    <div className="animate-ping opacity-75 inline-flex absolute -top-1 -right-3 justify-center items-center w-3.5 h-3.5 text-[10px] md:w-6 md:h-6 md:text-xs  text-white bg-red-500 rounded-full border-2  dark:border-gray-900">1</div>
                                    <div className=" inline-flex absolute -top-1 -right-3 justify-center items-center w-3.5 h-3.5 text-[10px] md:w-6 md:h-6 md:text-xs text-white bg-red-500 rounded-full border-2  dark:border-gray-900">1</div>

                                </div>
                                <hr className='ml-2 w-14 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                            </div>
                            <div className='text-center text-[10px] md:text-xs py-2 '>Email Campaign</div>
                        </div>
                    </div>

                </div>
                {/* col-3 */}
                <div className='flex flex-col justify-between border-dashed border-gray-800 '>
                    {/* <div className="">

                </div> */}
                    <div className="flex flex-col">

                    </div>
                    <div className="flex flex-col">
                        <div className=' '>
                            <div className=' flex items-center' data-tip data-for='lead-created'>
                                <ReactTooltip id='lead-created' place='top' type='info' effect='solid'>
                                    <div className='p-1 w-44 text-center'>Xbot check for leads with mobile number of email. If there will be no existing lead then a new lead will be created or else chat is transfered to chat agent</div>
                                </ReactTooltip>
                                <hr className='w-8 md:w-10 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                                <GoArrowSmallRight className='animate-myAnimation  text-gray-700 -ml-0.5' />
                                <img src={crm} alt="landing-page" className='w-8 md:w-16 grayscale hover:grayscale-0' />
                                <hr className='w-8 md:w-16 h-px border-dashed border-t border-gray-800 ' />
                            </div>
                            <div className='text-center text-[10px] md:text-xs '>Lead Created in CRM</div>
                        </div>
                        <div className='flex flex-col '>
                            <div className='flex justify-center '>
                                <hr className='w-px h-2 md:h-8 border-dashed border-l border-gray-800 dark:bg-gray-700' />
                            </div>
                            <div className='flex justify-center '>
                                <GoArrowSmallDown className=' text-gray-700 md:ml-0.5 ' />
                            </div>
                            <div className='-mb-7.5'>
                                <div className=' flex items-center'>
                                    <hr className='w-10 md:w-10 h-px border-dashed border-t border-transparent dark:bg-gray-700' />
                                    <GoArrowSmallRight className='animate-myAnimation  text-transparent -ml-0.5' />
                                    <div className='inline-flex relative items-center'>

                                        <img src={crmNotify} alt="landing-page" className='w-16 md:w-24 grayscale hover:grayscale-0' data-tip data-for='notify-agent' />
                                        <div>

                                            <div className="animate-ping opacity-75 inline-flex absolute -top-1 -right-2  md:-top-1 md:-right-3 justify-center items-center w-3.5 h-3.5 text-[10px] md:w-6 md:h-6 md:text-xs  text-white bg-red-500 rounded-full border-2  dark:border-gray-900">1</div>
                                            <div className=" inline-flex absolute -top-1 -right-2 md:-top-1 md:-right-3 justify-center items-center w-3.5 h-3.5 text-[10px] md:w-6 md:h-6 md:text-xs text-white bg-red-500 rounded-full border-2  dark:border-gray-900">1</div>
                                        </div>
                                        <div>

                                            <div className="animate-ping opacity-75 inline-flex absolute -top-1 -left-2 md:-top-1 md:-left-3 justify-center items-center w-3.5 h-3.5 text-[10px] md:w-6 md:h-6 md:text-xs  text-white bg-red-500 rounded-full border-2  dark:border-gray-900">1</div>
                                            <div className=" inline-flex absolute -top-1 -left-2 md:-top-1 md:-left-3 justify-center items-center w-3.5 h-3.5 text-[10px]  md:w-6 md:h-6 md:text-xs text-white bg-red-500 rounded-full border-2  dark:border-gray-900">1</div>
                                        </div>

                                    </div>
                                    <ReactTooltip id='notify-agent' place='bottom' type='info' effect='solid'>
                                        <div className='p-1 w-44 text-center'>Notification will be sent to Live Chat Agent on Whatsapp & Email</div>
                                    </ReactTooltip>
                                </div>
                                <div className='text-center text-[10px] md:text-xs '>CRM Based Notification</div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* col-4 */}
                <div className=' flex flex-col justify-center    '>
                    <div className='mt-16 md:mt-20'>
                        <div className='-mt-2 md:-mt-1 flex items-center z-10 ' data-tip data-for='convo'>
                            <ReactTooltip id='convo' place='top' type='info' effect='solid'>
                                <div className='p-1 w-44 text-center'>Conversation starts between user and chat agent</div>
                            </ReactTooltip>
                            <hr className=' w-10 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                            <GoArrowSmallRight className='animate-myAnimation  text-gray-700 -ml-0.5' />
                            <div className=''>

                                <img src={conversation} alt="landing-page" className=' w-16 grayscale hover:grayscale-0 ' />
                            </div>
                            <hr className='ml-2 w-14 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                        </div>
                        <div className=' relative text-center text-[10px] md:text-xs py-2 px-2'>
                            <p className='hidden md:contents'>
                                Xbot Panel for Whatsapp <br /> based conversation <br />between customer & <br />your chat agent
                            </p>
                            <p className='md:hidden'>
                                Chat <br />between customer & <br />chat agent
                            </p>
                        </div>
                    </div>


                </div>



                {/* col-5 */}
                <div className=' flex flex-col justify-between  border-l border-r border-dashed border-gray-800'>
                    <div className='-mt-6 my-2'>
                        <div className=' flex items-center z-10 ' data-tip data-for='interested'>
                            <ReactTooltip id='interested' place='top' type='info' effect='solid'>
                                <div className='p-1 w-44 text-center'>Sales team will do the follow-up. If user will be interested then an <span className='font-bold'>Interested Tag</span> will be added to CRM</div>
                            </ReactTooltip>
                            <hr className=' w-10 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                            <GoArrowSmallRight className='animate-myAnimation  text-gray-700 -ml-0.5' />
                            <div className='mt-0'>

                                <img src={response} alt="landing-page" className='animate-bounce w-12 grayscale hover:grayscale-0 ' />
                            </div>
                            <hr className='ml-2 w-16 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                        </div>
                        <div className='text-center text-[10px] md:text-xs py-2 '>Interested</div>
                    </div>
                    <div className='my-2' data-tip data-for='noresp'>
                        <ReactTooltip id='noresp' place='top' type='info' effect='solid'>
                            <div className='p-1 w-44 text-center'>If there will be no response on follow-up by user then a <span className='font-bold'>Not Responding</span> tag will be added to CRM</div>
                        </ReactTooltip>
                        <div className='flex items-center '>
                            <hr className='w-10 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                            <GoArrowSmallRight className='animate-myAnimation text-gray-700 -ml-0.5' />
                            <div className=''>
                                <img src={noResp} alt="landing-page" className=' z-10 w-16 grayscale hover:grayscale-0' />
                            </div>
                            <hr className='ml-2 w-14 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                        </div>
                        <div className='text-center text-[10px] md:text-xs py-2 '>No Response</div>
                    </div>

                    <div>
                        <div className='-mb-16 my-2' >
                            <div className=' flex items-center  z-20 bg-red-0' data-tip data-for='junk'>
                                <ReactTooltip id='junk' place='top' type='info' effect='solid'>
                                    <div className='p-1 w-44 text-center'>If lead will respond negatively then, it will be marked as <span className='font-bold'>Junk Lead</span> in CRM</div>
                                </ReactTooltip>
                                <hr className=' w-12 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                                <GoArrowSmallRight className='animate-myAnimation  text-gray-700 -ml-0.5' />
                                <div className=''>
                                    <img src={junk} alt="landing-page" className='w-10 grayscale hover:grayscale-0' />
                                </div>
                                <hr className='ml-2 w-16 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                            </div>
                            <div className='text-center text-[10px] md:text-xs py-2 '>Not Interested / Junk Lead</div>
                        </div>
                    </div>

                </div>
                <div className=' flex flex-col justify-center    '>
                    <div className='mt-8'>
                        <div className='-mt-1 flex items-center z-10 ' data-tip data-for='crmupdate'>
                            <ReactTooltip id='crmupdate' place='top' type='info' effect='solid'>
                                <div className='p-1 w-44 text-center'>Finally, data will be  <span className='font-bold'>updated</span> in CRM</div>
                            </ReactTooltip>
                            <hr className=' w-10 h-px border-dashed border-t border-gray-800 dark:bg-gray-700' />
                            <GoArrowSmallRight className='animate-myAnimation  text-gray-700 -ml-0.5' />
                            <div className=''>

                                <img src={crmUpdated} alt="landing-page" className=' w-16 grayscale hover:grayscale-0 ' />
                            </div>

                        </div>
                        <div className='relative text-center text-[10px] md:text-xs py-2 px-2'>Data Updated in CRM
                        </div>
                    </div>


                </div>
            </div>
        </div >
    )
}

export default IntegrateWithCrm