import React from 'react'
import { Link } from 'react-router-dom'
import xbotfooter from '../xbot-white.png'
import WebChat from './WebChat'
function Footer() {
    return (

        <footer className="p-4 bg-slate-900 sm:p-6 dark:bg-gray-900">
            <WebChat />
            <div className="hidden md:content md:grid md:grid-cols-5 gap-6">
                <div className="  flex items-center px-4">
                    <Link to="/" className="flex items-center">
                        <img src={xbotfooter} className="mr-3 h-12" alt="xbot Logo" />
                    </Link>
                </div>
                <div>
                    <h2 className="py-2 text-sm font-semibold text-white uppercase dark:text-white">Important Links</h2>
                    <ul className="text-gray-300 dark:text-gray-400">
                        <li className="py-0.5">
                            <a href="https://chat-xbot.webspecia.in/login" className="text-sm hover:underline">Login</a>
                        </li>
                        <li className="py-0.5">
                            <a href="https://booking.webspecia.in/" className="text-sm hover:underline">Book a demo</a>
                        </li>
                        <li className="py-0.5">
                            <Link to="/about-us" className="text-sm hover:underline">About Us</Link>

                        </li>
                    </ul>
                </div>
                <div>
                    <h2 className="py-2 text-sm font-semibold text-white uppercase dark:text-white">Resources</h2>
                    <ul className="text-gray-300 dark:text-gray-400">
                        <li className="py-0.5">
                            <a href="https://www.youtube.com/user/WebSpecia" className="text-sm hover:underline">YouTube</a>
                        </li>
                        <li className="py-0.5">
                            <a href="https://help.webspecia.in/portal/en/home" className="text-sm hover:underline">Help & Support</a>
                        </li>

                    </ul>
                </div>
                <div>
                    <h2 className="py-2 text-sm font-semibold text-white uppercase dark:text-white">Why Xbot</h2>
                    <ul className="text-gray-300 dark:text-gray-400">
                        <li className="py-0.5">
                            <Link to="/xbot-vs-wati" className="text-sm hover:underline">Xbot vs Wati</Link>
                        </li>
                        <li className="py-0.5">
                            <Link to="/xbot-vs-yellow-ai" className="text-sm hover:underline">Xbot vs Yellow.ai</Link>
                        </li>
                        <li className="py-0.5">
                            <Link to="/xbot-vs-haptic" className="text-sm hover:underline">Xbot vs Haptic</Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 className="py-2 text-sm font-semibold text-white uppercase dark:text-white">Legal</h2>
                    <ul className="text-gray-300 dark:text-gray-400">
                        <li className="py-0.5">
                            <Link to="/privacy-policy" className="text-sm hover:underline">Privacy Policy</Link>
                        </li>
                        <li className="py-0.5">
                            <Link to="/tos" className="text-sm hover:underline">TOS</Link>
                        </li>
                        <li className="py-0.5">
                            <Link to="/contact-us" className="text-sm hover:underline">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='hidden md:contents'>
                <hr className=" my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            </div>
            <div className="flex items-center justify-center">
                <span className="text-sm text-gray-300 text-center dark:text-gray-400">A Webspecia Company © 2022 | <a href="https://xbot.webspecia.in" className="hover:underline">Xbot ™</a>. All Rights Reserved.
                </span>

            </div>
        </footer>

    )
}

export default Footer