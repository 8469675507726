import React from 'react'
import HeroImg from './../GovtBot/media/hero.png'
import WhatsappHeroSlider from '../InnerTemplate/WhatsappHeroSlider'
import WhyDoYouNeed from '../InnerTemplate/WhyDoYouNeed'
import WhyImg from './../GovtBot/media/hero.png'
import Cta from '../InnerTemplate/Cta'
import WhatsappSolution from '../InnerTemplate/WhatsappSolution'
import sol1Img from './../GovtBot/media/sol1.png'
import sol2Img from './../GovtBot/media/sol2.png'
import sol3Img from './../GovtBot/media/sol3.png'
import sol4Img from './../GovtBot/media/sol4.png'
import Clients from '../Clients'
import CrossPlatform from '../CrossPlatform'
import Pricing from '../Pricing'
import { Helmet } from 'react-helmet'

function GovtBot() {
    return (
        <div className="pt-10">
            <Helmet>‍
                <title>WhatsApp Chatbots for Government | Xbot by Webspecia</title>‍
                <meta name="description" content="Xbot's WhatsApp chatbots can help government organizations streamline services and save resources. Our chatbots can handle a high volume of inquiries, facilitate transactions, and integrate with existing systems. Learn how Xbot's chatbots can improve efficiency and productivity for your government organization." />

            </Helmet>
            <div>
                <WhatsappHeroSlider
                    heroimg={HeroImg}
                    HeroHeading={`Serve your citizens 24*7 with chatbots for Government`}
                />
            </div>


            <div >
                <Clients title={`Try us, you're in good company`} />
            </div>
            <div>
                <WhyDoYouNeed
                    heading={'Why your business needs an AI chatbot?'}
                    fOneHeading={'51%'}
                    fOneText={'Increase in citizen’s engagement with multilingual support'}
                    fTwoHeading={'44%'}
                    fTwoText={'Of citizen’s inquiries answered by the chatbot'}
                    fThreeHeading={'80%'}
                    fThreeText={'Reduction in wait time'}
                    fFourHeading={'35%'}
                    fFourText={'Reduction in wait time'}
                    WhyImg={WhyImg}
                />
            </div>

            <div >
                <Cta />
            </div>
            <div>
                <WhatsappSolution
                    heading={`How can Xbot grow your business?`}
                    sol1Title={`Automate your monotonous tasks & processes using a government chatbot`}
                    sol1Desc={`Filing applications, streamlining the processes, and handling higher volumes of requests - all these processes can be automated within seconds with Xbot's chatbots for Governments.`}
                    sol1Img={sol1Img}

                    sol2Title={`Keep all your citizen’s data secure`}
                    sol2Desc={`Ensure security and compliance with Xbot’s easy-to-use, secure platform. Crafted to handle federal and personal data while allowing staff members to collaborate securely, it’s the perfect chatbot for government services.`}
                    sol2Img={sol2Img}

                    sol3Title={`Connect with citizens on the channel of their choice - WhatsApp`}
                    sol3Desc={`Leverage WhatsApp message templates & engage with your citizens for sharing notifications on all the news, updates, new processes & more!`}
                    sol3Img={sol3Img}

                    sol4Title={`Remotely serve citizens 24/7`}
                    sol4Desc={`With Xbot’s  government chatbot, citizens can now book appointments anytime, anywhere using the WhatsApp bot and get their queries resolved in real-time.`}
                    sol4Img={sol4Img}
                />
            </div>
            <div name="pricing" >
                <Pricing />
            </div>
            <div className=''>
                <Cta />
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />

            </div>

        </div>
    )
}

export default GovtBot
