import React from 'react'
import { useState, useEffect } from 'react'


function Iptest() {
    const [details, setDetails] = useState(null);

    useEffect(() => {
        fetch(
            "https://geolocation-db.com/json/89eb4d70-4cbe-11ed-a0f2-51b843ebe8d7"
        )
            .then(response => response.json())
            .then(data => setDetails(data));


    }, [])

    return (
        <div className='flex flex-col justify-center mt-24 my-auto'>


            <div className="row mx-48 flex justify-center">

                {details &&
                    <div>
                        <div>    country is {details.country_name}</div>
                        <div> country code id {details.country_code}</div>
                    </div>
                }



            </div>

        </div>
    )
}

export default Iptest
