import React from 'react'
import { Helmet } from 'react-helmet'

function TermsCondition() {
    document.title = "Terms of Services - Xbot ";

    return (
        <div className='px-4 md:mt-20 md:mb-10 md:px-32'>
            <Helmet>‍
                <title>Terms and Conditions | Xbot by Webspecia</title>‍
                <meta name="description" content="Read through the agreement for using Xbot's WhatsApp chatbot services, including details on how the chatbot works, how data is collected and used, and any limitations or exclusions. By using Xbot's chatbot services, you are agreeing to these terms and conditions." />

            </Helmet>
            <div>

                < h2 className='py-4 text-3xl font-bold ' > Terms Of Service </h2 >
                <h5 className='text-xl font-medium pt-4 mb-3'>
                    IMPORTANT. READ CAREFULLY.
                </h5>
                <p className='mb-4'>
                    These terms and conditions for software licensing and its services (“Terms of Use”), is a legal agreement between Avokis Technology Pvt. Ltd, (CIN U72300BR2014PTC022873), a company incorporated under the laws of India with its registered office at Mahatawna, Behind M.S.M. Imrat Sharia Hospital, Phulwarisharif, Patna – 801505, Bihar, INDIA, whether acting on behalf of itself and/or on behalf of any of its affiliates and/or its third party suppliers and/or its licensors and/or service providers (hereinafter referred to as “Company” or “Our” or “Us”) and merchants (whether an individual or a legally recognized entity) involved in any lawful business for provision of any services or goods, across the territory of India (“Client”, “You” or “Your”).
                    ‍
                </p>

                <p className='mb-4 pt-3'>
                    The Company, inter alia, offers a suite of messaging software-as-a-service solutions through a single platform to enable its customer to manage communications with their users under the name and style of “OmniXBot” (“Solution”).
                </p>
                <p className='mb-4'>
                    The Company may, at its sole discretion, authorize such persons from time to time, who shall carry out all or any of its rights, duties and obligations under these Terms of Use and any reference to “Avokis Technology” under these Terms of Use shall be construed to mean and include any such persons duly authorised and acting on behalf of the Company.
                </p>
                <p className='mb-4'>
                    These Terms of Use are an electronic record in terms of the Information Technology Act, 2000, the applicable rules thereunder and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. These Terms of Use are generated by a computer system and does not require any physical or digital signatures.
                </p>
                <p className='mb-4'>
                    These Terms of Use are published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing of the rules and regulations, privacy policy and terms of use for access to or usage of any electronic devices and services.
                </p>
                <p className='mb-4 font-medium'>
                    BY USING THE SOLUTION, THE CLIENT ACCEPTS THE TERMS OF THESE TERMS OF USE AND ANY ADDENDUM OR ANNEXURE BEING PART OF THESE TERMS OF USE. IF THE CLIENT DOES NOT ACCEPT THESE TERMS, THE CLIENT SHOULD NOT USE THE SOLUTION. THE COMPANY MAY MODIFY OR UPDATE THESE TERMS OF USE FROM TIME TO TIME AND THE SAME SHALL BE MADE AVAILABLE AT [https://xbot.webspecia.in/tos] FOR ACCESS BY THE CLIENT.
                </p>
                <h5 className='text-xl font-medium pt-4 mb-3'>
                    DEFINITIONS:
                </h5>
                <p className='mb-3'>
                    In these Terms of Use, (i) capitalized terms defined by inclusion in quotations and/or parenthesis have the meanings so ascribed; and (ii) the capitalized terms as set out below shall have the meanings as indicated therein. In these Terms of Use, the headings are for convenience only and shall not in any way define or limit the scope.
                </p>
                <p className='mb-4'>
                    “Applicable Law” means and includes all applicable Indian statutes, enactments, acts of legislature or parliament, laws, ordinances, rules, bye-laws, regulations, notifications, guidelines, policies, directions, directives and orders of any governmental authority or self-regulatory agency, statutory authority, tribunal, board, court in India.
                </p>
                <p className='mb-4'>
                    “Applicable Data Protection Law” means all applicable Indian government-issued Laws, rules, regulations and guidance pertaining to privacy, data processing, data protection, data security, encryption, or confidentiality including but not limited to the Information Technology Act, 2000.
                </p>
                <p className='mb-4'>
                    “Beta Products” means a pre-released version of the Solution or any of its components circulated to you to try under realistic conditions which are not pre-simulated.
                </p>
                <p className='mb-4'>
                    “Business Day” shall mean any day other than Saturday, Sunday or any public holidays, on which the banks in Delhi are open for business.
                </p>
                <p className='mb-4'>
                    “Intellectual Property” means and includes all intellectual property, in any part of the world, whether registered or not registered, and in particular (i) all trademarks, service marks, trade names, logos, domain names; patents, design rights; trade secrets, including, know-how, technology, formulae, industrial and commercial information, techniques and inventions; processes, manuals, documentation, and technical data and information; copyrights, works of authorship, and topography rights, database rights; computer hardware and software including source code, computer programs, user interfaces, software applications, software platform or infrastructure and any other information in relation to the above; (ii) all rights under licenses in respect of all of the above; (iii) any applications or registrations for the protection of all of the rights specified at sub-clause (i) and (ii) herein above; and (iv) all renewals and extensions thereof; and the term “Intellectual Property Rights” shall be construed accordingly.
                </p>
                <p className='mb-4'>
                    “Personal Data” means any information relating to an identified or identifiable natural person or that is otherwise considered personally identifiable information, personal information, or personal data under Applicable Data Protection Law.
                </p>
                <p className='mb-4'>
                    “SaaS” means software as a service.
                </p>
                <p className='mb-4'>
                    “Subscription Commencement Date” means the date on which the Client accepts and acknowledges these Terms of Use.
                </p>
                <p className='mb-4'>
                    “Scheduled Maintenance” means the Company’s scheduled routine maintenance of the Solution for which the Client shall be notified at least two (2) hours in advance and shall not exceed eight (8) hours per week.
                </p>
                <p className='mb-4'>
                    “Third Party Services” means the third-party services made available by the Company under the Solution including but not limited to WhatsApp.
                </p>
                <p className='mb-4'>
                    “Update” means the modifications or revisions made to the Solution: (i) to improve upon or repair existing features and operations within the Solution; (ii) to ensure compatibility with new releases of existing systems (including hardware, operating systems and middleware) and external services through standardized interfaces; (iii) to comply with Applicable Laws, regulations, industry standards or market practice, other than an Upgrade.
                </p>
                <p className='mb-4'>
                    “Upgrades” means new versions of the Solution intended to enhance the functionality of the Solution and that may change the version number of such Solution.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    TERM:
                </h5>
                <p className='mb-4 pt-3'>
                    These Terms of Use are effective until terminated in accordance with the terms set forth herein (“Term”) and will be automatically renewed beyond the Term (“Auto Renewal”), upon the same terms and conditions as set forth herein, unless expressly revoked by the Client in writing.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    GRANT OF LICENSE:
                </h5>
                <p className='mb-4 pt-3'>
                    During the Term, in consideration of payment of the Fees by the Client to the Company and subject to the terms and conditions herein, the Company grants to the Client, a limited, non- exclusive, personal, revocable, non- transferable and non- licensable license to access and use the Solution on a subscription basis, in accordance with these Terms of Use. Nothing contained herein shall be construed as creating any arrangement for transfer of title, ownership or interest including rights under any Intellectual Property in or of the Solution in favour of the Client.
                </p>
                <p className='mb-4'>
                    The Solution shall be made available by the Company to the Client as a service on a SaaS model, whereby the Company will provide the Client access to the Solution by hosting the Solution on a centrally hosted system, to which the Client will be given necessary access. The Company reserves for itself all other rights and interests not explicitly granted under these Terms of Use.
                </p>
                <p className='mb-4'>
                    The Client’s subscription to the Solution shall commence on the Subscription Commencement Date, irrespective of the Trial Period. The Client agrees and acknowledges that any delay in implementation of the Solution that can be attributed to the Client, illustratively, due to non-provision or delay in provision of any material, information or documents requisitioned by the Company for the purposes of implementing the Solution, will not result in any change of the Subscription Commencement Date and will be liable to pay the Fees in accordance with the terms set out in these Terms of Use.
                    ‍
                </p>
                <p className='mb-4'>
                    Notwithstanding the foregoing, the Client acknowledges and agrees that the use of the Solution shall be subject to such additional terms and conditions and privacy policy, as may be applicable thereto and available at [insert the link for Privacy Policy], including any updates thereto from time to time. For avoidance of doubt, these Terms of Use shall be in addition to and not in derogation of any terms and conditions and privacy policy applicable to the Solution. Such terms and conditions and the privacy policy applicable shall be read into and shall form an integral part of these Terms of Use.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    LICENSE AND USE OF SOLUTION
                </h5>
                <p className='mb-4 pt-3'>
                    For availing the Solution, the Client hereby expressly consents and agrees to provide all information, including any applicable documents required by the Company, including Client’s identity, address and credit information, to the Company, from time to time.
                    ‍
                </p>
                <p className='mb-4'>
                    The Client acknowledges and agrees that the Solution provided under these Terms of Use, during the Term is non- exclusive in nature and that the Company shall be entitled, at all times, to deal with the Solution in any manner it deems fit which includes provision of services using the Solution by itself or any variation, Update or Upgrades through any third party, from time to time.
                </p>
                <p className='mb-4'>
                    At any time, the Solution shall include all modifications, Updates, future or new Upgrades, additions, at the sole discretion of the Company. It is hereby clarified that the Client’s continued use of the Solution pursuant to any such Updates and Upgrades will be considered deemed acceptance of such Updates and Upgrades.
                </p>
                <p className='mb-4'>
                    The License granted under these Terms of Use is only for the limited use of the Solution by the Client for its business operations and shall not include, without limitation, the right to:
                </p>
                <ul className='px-10 mb-4'>
                    <li><p>(a) license, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Solution or the content in any way;</p>
                    </li>
                    <li>
                        <p>(b) circumvent or disable any security or other technical features of the Solution;</p>
                    </li>
                    <li>
                        <p>(c) modify, reproduce or make derivative works based on the Solution or the content;</p>
                    </li>
                    <li>
                        <p>(d) create internet “links” to the Solution or “frame” or “mirror” any content on any other server or wireless or internet-based device; </p>
                    </li>
                    <li>
                        <p>(e) reverse engineer or access the Solution for any purpose whatsoever, including without limitation, to (i) build a competitive product or service, (ii) build a product using similar ideas, features, functions or graphics of the Solution; or (iii) to copy any ideas, features, functions or graphics of the Solution; or</p>
                    </li>
                    <li>
                        <p>(f) use the Solution for any purpose other than in connection with the Client’s internal business operations.</p>
                    </li>
                </ul>
                <p className='mb-4'>
                    The provisions of this Clause 5 (License and Use of Solution) constitute a material term under these Terms of Use; the breach of which by the Client shall constitute an immediate and material breach and termination hereof. The Client agrees that the use of the Solution, or any part thereof, by any party other than the Client will not be permitted, unless specifically approved by the Company in writing.
                </p>
                <p className='mb-4'>
                    IMPORTANT: Client will need to maintain access and be solely responsible for the registered SIM card and will need to present the same in cases of emergencies in no longer than 30 minutes.‍
                </p>
                <p className='mb-4'>
                    The Solution shall, inter alia, consist of the following components:
                </p>
                <ul className='px-10 mb-4'>
                    <li>(a) Client Data. The Solution shall be structured entirely on the Company’s proprietary platform under the name and style [‘interakt’] and shall integrate within various information and inputs in relation to the business and user/ customer details of the Client, as provided to Company on the Subscription Commencement Date, or at any time during the usage of the Solution. </li>
                    <li>(b) Inbox Dashboard: The Client shall have access to its ‘Inbox Dashboard’, a web-based messaging application forming part of the Solution wherein the messages sent by the Client’s users is automatically visible to the Client in their messaging application such as WhatsApp/WhatsApp Business</li>
                    <li>(c) Support and Assistance: The Company shall provide prompt support and assistance to the Client in case of any exigencies, breakdown, error or deficiency in the Solution that may affect the use of the Solution by the Client. </li>
                    <li>(d) Early Access to Beta Products: The Company may reach out to the Client in the event of testing any Beta Products, with a written request to the Client. Such early access to the Beta Products shall be made available to the Client on an ‘as is’, and ‘as available’ basis and to the extent permitted under Applicable Laws, without any warranties, representations, indemnities, or contractual commitments of any kind.</li>
                </ul>

                <h5 className='text-xl font-medium pt-4 '>
                    RIGHTS AND OBLIGATIONS OF THE CLIENT
                </h5>
                <p className='mb-4 pt-3'>
                    (i) Client’s unauthorized access: The Client shall take all reasonable measures to ensure that the information transmitted to or from the Company servers are secure and shall not authorize any third person to have any unauthorized access to the Company servers. The Client may authorize any of its personnel to use the Solution and must ensure that such authorised personnel follows reasonable security measures as would have been followed by the Client itself and in case of any default on the part of such authorised personnel shall be deemed to be a default by the Client under these Terms of Use.
                </p>
                <p className='mb-4'>
                    (ii) Client Compliance with the law: The Client shall ensure that it has taken all necessary approvals, licenses from concerned authority(ies) within or outside the territory for utilizing the Solution from the Company. The Client shall be liable for any default committed by it or by any of its personnel, employees, consultants and any other personnel authorised on its behalf and shall be solely responsible for any claims or liabilities that may arise on account of any such default.
                </p>
                <p className='mb-4'>
                    (iii) Client Cooperation: The Client shall cooperate with the Company for any integration, as may be required under these Terms of Use into its system and shall provide the Company unfettered access to its systems and computing platform as and when required.
                </p>
                <p className='mb-4'>
                    (iv) Client Payment: The Client shall pay any and all payments due under these Terms of Use, as and when requested by the Company.
                </p>
                <p className='mb-4'>
                    (v) Trial Period: In furtherance of the Company’s efforts to ensure client satisfaction, the Client shall, for usage of the Solution, may be placed on trial for a period of 7-14 days (“Trial Period”) from the Subscription Commencement Date and may be entitled to a limited/ complete use of the Solution for such Trial Period. The rights and obligations set out in these Terms of Use shall also apply to the use of the Solution during the Trial Period. The Client shall be entitled to terminate these Terms of Use on or before the expiry of the Trial Period, without payment of any Subscription Fees. However, on the expiry of the Trial Period, the Client shall be automatically charged for the Subscription Fees and/or the Usage Fees, as the case may be.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    LICENSE FEE AND PAYMENT TERMS
                </h5>
                <p className='mb-4 pt-3'>
                    In consideration of grant of License, the Client shall pay the Company, the payments as set out below:
                </p>
                <p className='mb-4'>
                    (a) Subscription Fee: The subscription period of the Solution shall commence from the Subscription Commencement Date and shall continue until the Subscription End Date, unless agreed otherwise between the Parties. On the Subscription Commencement Date, the Client agrees to provide appropriate details for the Company to process the payment of a non- refundable fee for subscribing the Solution, at the end of the Trial Period. The Client shall make the payment of the amount as indicated by the Company for the License of the Solution (“Subscription Fees”).
                </p>
                <p className='mb-4'>
                    (b) Usage Fee: In addition to the Subscription Fee as set forth above, the Client shall also be liable to pay a monthly usage fee as linked to the utilization of the Solution, if such Client exceeds the usage limit offered under the Subscription Fee model, in the manner as notified to the Client by the Company (“Usage Fees”). (The Subscription Fees and the Usage Fees shall hereinafter collectively be referred to as “Fees”).
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    Cancellation & Refund Policy
                </h5>
                <p className='mb-4 pt-3'>
                    (a) Cancellation: Client can cancel anytime right in the billing section of the account.
                </p>
                <p className='mb-4'>
                    (b) Refund Policy: We strive to built the platform that can help client to automate business without coding. But if any client will be unhappy with omniXBot for any reason, you are protected by our no-question-asked refund within 30 days of purchase.
                </p>
                <p className='mb-4'>
                    All payments stipulated under these Terms of Use are exclusive of all government levies and taxes and all applicable taxes and charges payable on the Fees, including any goods and service tax, shall be borne by the Client at all times. The Client shall additionally bear to its own account all other taxes, as may be applicable, for payments made to the Company pursuant to these Terms of Use. The Client shall comply with all filing formalities and requirements in respect of any tax withholdings to be made, including the filing of withholding tax return as prescribed under the applicable tax laws and issue to the Company, a certificate for tax deducted or paid on its behalf at source in the form of a certificate issued in accordance with applicable tax laws. The Client shall make best efforts to ensure that the Company gets credit for any tax deducted and provide to the Company, all information that is necessary to assist the Company in connection with its tax and statutory obligations.

                </p>
                <h5 className='text-lg font-medium pt-4 '>
                    General Payment Terms:
                </h5>
                <p className='mb-4 pt-3'>
                    ‍The Client agrees that all payments required to be made by the Client to the Company under these Terms of Use shall be in accordance with the following terms:
                </p>
                <p className='mb-4'>
                    (a) The Client’s obligation to pay the amounts stipulated under these Terms of Use is absolute and shall not be negated by the Company not raising an invoice.
                </p>
                <p className='mb-4'>
                    (b) The Client shall pay the Subscription Fee, immediately or at the end of the Trial Period as applicable; and (ii) the Usage Fee by the 5th (Fifth) day of every month for the work performed in the previous month.
                </p>
                <p className='mb-4'>
                    (c) The Company shall have the right to suspend the Client’s access and use of the Solution, if the Usage Fee has not been paid within a stipulated time period indicated by the Company.
                </p>
                <p className='mb-4'>
                    (d) In the event of any payment default by the Client in making any payment (whether in whole or part), the pending amount due from such Client, shall be subject to interest from the stipulated due date until the date of repayment, at a rate equal to 2% (two percent) per month (i.e. 24% (twenty four percent) annually) or the maximum allowed under Applicable Law, whichever is lesser.
                </p>
                <h5 className='text-lg font-medium pt-4 '>
                    REPRESENTATIONS AND WARRANTIES
                </h5>
                <p className='mb-4 pt-3'>
                    Each Party hereby represents and warrants to the other that:
                </p>
                <p className='mb-4'>
                    it is duly incorporated and validly existing under the Applicable Laws;
                </p>
                <p className='mb-4'>
                    it has taken all actions including any corporate actions necessary to execute, deliver and to perform obligations under these Terms of Use;
                </p>
                <p className='mb-4'>
                    it has obtained all the statutory approvals/ permissions/ no objections as necessary and required under the Applicable Law for carrying on its activities and related services;
                </p>
                <p className='mb-4'>
                    there are no judicial or administrative actions, proceedings or investigations pending or, to the best of its knowledge after due inquiry, overtly threatened against it, which would have a materially adverse effect on its capacity to perform the obligations under these Terms of Use;
                </p>
                <p className='mb-4'>
                    it is not subject to the obligations under any contract as a consequence of which it may be in breach of its obligations and covenants contained in these Terms of Use.
                </p>

                <p className='mb-4'>
                    In addition to the general representations as set out above, the Client, further represents and warrants as follows:
                </p>
                <p className='mb-4'>
                    it possesses the financial ability to make payment of Fees in relation to the Solution;
                </p>
                <p className='mb-4'>
                    it has procured and shall at all times during the Term continue to hold, all applicable and necessary third party approvals/consents, governmental approvals, registrations, authorizations, licenses, permits and any other permissions whatsoever required under the Applicable Law to utilize the Solution; and
                </p>
                <p className='mb-4'>
                    it is as on the Subscription Commencement Date e, and shall at all times during the Term continue to be, in full compliance with all Applicable Laws, including the Applicable Data Protection Laws.
                </p>
                <h5 className='text-lg font-medium pt-4 '>
                    RIGHT TO SUSPEND CLIENT’S ACCESS TO THE SOLUTION
                </h5>
                <p className='mb-4 pt-3'>
                    (i) Company’s right to suspend access to the Solution: The Company may suspend the Client’s right to access or use any portion or all of the Solution immediately without notice to the Client if it determines that:
                </p>
                <p className='mb-4'>
                    The Client’s use of the Solution: (i) poses a security risk to the Company, the Solution or any third party or; (ii) may adversely impact Company’s other services and products including the Solution or the systems or content of any of other customers of the Company; (iii) may subject the Company or its Affiliates, or any third party to liability; or (iv) may be fraudulent;
                </p>
                <p className='mb-4'>
                    The Client is in breach of these Terms of Use, including if the Client is delinquent on its payment obligations as stipulated under Clause 7 (License Fee and Payment Terms); or
                </p>
                <p className='mb-4'>
                    The Client ceases to operate in the ordinary course, has made an assignment for the benefit of creditors or similar disposition of assets, or become the subject of any bankruptcy, reorganization, liquidation, dissolution or similar proceeding.
                </p>
                <p className='mb-4'>
                    (ii) Effect of suspension: If the Company suspends the Client’s right to access or use any portion or all of the Solution in accordance with Clause 9(i) above;
                </p>
                <p className='mb-4'>
                    The Client shall remain responsible for all Fees incurred through the date of suspension;
                </p>
                <p className='mb-4'>
                    The Client shall remain responsible for all applicable Fees for any portion of the Solution to which the Client continues to have access, as well as any applicable data storage fees and charges, and fees and charges for in-process tasks completed and incurred through the date of suspension;
                </p>
                <p className='mb-4'>
                    The Company shall not erase any of the Clients’ content or data, uploaded prior to such suspension, as a result of suspension, except as specified elsewhere in these Terms of Use.
                </p>
                <p className='mb-4'>
                    The Company’s right to suspend the Client’s right to access or use the Solution is in addition to the Company’s right to terminate these Terms of Use pursuant to Clause 12 (Termination of the Agreement) herein.
                </p>
                <h5 className='text-lg font-medium pt-4 '>
                    CONFIDENTIALITY
                </h5>
                <p className='mb-4 pt-3'>
                    (i) Definition and Exclusions:
                </p>
                <p className='mb-4'>
                    (a) For purpose of these Terms of Use, “Confidential Information” shall include any and all confidential and proprietary information or material (including information, data and materials relating to current or prospective products and processes) made available (whether intentionally or otherwise) to a Party or its affiliates or subcontractors, or its or its affiliates’ or its subcontractors’ employees, agents and other representatives, by or on behalf of the other Party and relating to the business, operations, affairs, technologies, plans and strategies of such other Party, whether observed or provided orally, in written, graphic or electronic form, or in the form of samples, and whether or not marked, labeled or otherwise identified as “confidential,” “secret” or “proprietary” (it being acknowledged and agreed that the existence and terms of these Terms of Use will be considered to be Confidential Information as to both Parties).
                </p>
                <p className='mb-4'>
                    (b) Notwithstanding above, the Confidential Information does not include any information or items that: (i) were generally available to the public, or otherwise part of the public domain, when received by the receiving Party; (ii) become generally available to the public, or otherwise becomes part of the public domain, other than through breach of these Terms of Use; (iii) can be demonstrated by the receiving Party to have already been in its possession, or otherwise known by it, prior to the time of receipt from the disclosing Party; (iv) are received by the receiving Party from a third Party that is (1) in lawful possession thereof; and (2) under no confidentiality obligation to the disclosing Party; or (v) are independently developed by the receiving Party without use of the Confidential Information of the disclosing Party that has or could have commercial value or other utility in disclosing Party’s business and is treated with confidentiality.
                </p>
                <p className='mb-4'>
                    (ii) Disclosure of Confidential Information
                </p>
                <p className='mb-4'>
                    Each Party hereby agrees and acknowledges that it shall:
                </p>
                <p className='mb-4'>
                    (a) use the same level of care to prevent disclosure of the Confidential Information of the disclosing Party to any third parties as it employs to avoid disclosure, publication, or dissemination of its own information of a similar nature, but in no event less than a reasonable standard of care;
                </p>
                <p className='mb-4'>
                    (b) use the Confidential Information of the disclosing Party solely for the purpose of performing its obligations under these Terms of Use;
                </p>
                <p className='mb-4'>
                    (c) not acquire any right in or assert any lien against Confidential Information of the disclosing Party;
                </p>
                <p className='mb-4'>
                    (d) promptly return, or provide a copy of, as the disclosing Party directs, Confidential Information upon its request;
                </p>
                <p className='mb-4'>
                    (e) notwithstanding the foregoing, the receiving Party may disclose Confidential Information of the disclosing Party to its employees, agents, and subcontractors who have: (i) a need to know such Confidential Information in order to perform their duties; and (ii) a legal duty to protect the Confidential Information. The receiving Party assumes full responsibility for the acts or omissions of its subcontractors and employees with respect to such Confidential Information; and
                </p>
                <p className='mb-4'>
                    (f) immediately notify the disclosing Party, in the event of any unwanted disclosure or loss or breach of Confidential Information.
                </p>
                <p className='mb-4'>
                    (iii) Required Disclosure
                </p>
                <p className='mb-4'>
                    The receiving Party may disclose Confidential Information received from the disclosing Party, to the extent required by Applicable Laws or by order of a court or governmental agency; provided, however, that the receiving Party shall give the disclosing Party prompt notice and shall use its best efforts to cooperate with the disclosing Party if it wishes to obtain a protective order or otherwise protect the confidentiality of such Confidential Information, as the case may be. Further, the receiving Party may disclose the terms of these Terms of Use to the extent required to enforce its terms or its rights. The confidentiality obligations shall survive the termination of these Terms of Use.
                </p>
                <p className='mb-4'>
                    (iv) Equitable Relief
                </p>
                <p className='mb-4'>
                    Each Party acknowledges that any breach of the confidentiality obligations under these Terms of Use by the receiving Party or, or its personnel or subcontractors, will cause immediate and irreparable injury to the disclosing Party, and in the event of any such breach, the disclosing Party shall be entitled to injunctive relief, without bond or other security, and to any and all other remedies available at law or in equity.
                </p>
                <p className='mb-4'>
                    (v) Return of Confidential Information
                </p>
                <p className='mb-4'>
                    Unless it is expressly authorized by these Terms of Use to retain the disclosing Party’s Confidential Information, the receiving Party shall, either at the request of the disclosing Party or on termination and/or expiry of these Terms of Use, promptly return or destroy with a confirmation in writing, any and all Confidential Information received and all copies thereof.
                </p>



                <h5 className='text-xl font-medium pt-4 '>	TERMINATION:	</h5>
                <p className='mb-4'>	These Terms of Use shall be terminated by the Client, on or before the Trial Period. The Client may terminate these Terms of Use, after the Trial Period, if it does not exercise the option of Auto Renewal and notifies the same in writing to the Company. On receipt of such written notification, the Company shall terminate the Client’s access to the Solution (“Subscription End Date”). 	</p>
                <p className='mb-4'>	(ii) Effect of Termination:</p>

                <p className='mb-4'>	(a) On the Subscription End Date, , all rights and benefits granted herein shall revert to respective Parties, and all amounts due from the Client till the Subscription End Date shall remain payable.	</p>

                <p className='mb-4'>	(b) On the Subscription End Date, the Client’s right to access and/or usage of the Solution shall immediately cease and the Client shall have no other or further right to access and use the Solution.	</p>

                <p className='mb-4'>	(c) The Client shall be required to destroy all Confidential Information, promotional and other materials or documentations (if any) furnished as well as any manuals, instruction booklets in any version or medium as provided by the Company to the Client pursuant to these Terms of Use. However, if the Client retains any copies of the Confidential Information after the Subscription End Date pursuant to any automatic archiving and back up procedures, the obligations set out under Clause 10 (Confidentiality) shall continue to apply.	</p>

                <h5 className='text-xl font-medium pt-4 '>	SERVICE LEVELS: 	</h5>
                <p className='mb-4'>	The utilisation of the Solution under these Terms of Use shall be measured against the service levels and standards, as established in good faith by mutual agreement between the Parties (“SLA”).  	</p>

                <p className='mb-4'>	Target Availability. The Company will use commercially reasonable efforts to make the Solution available with an uptime of 95% (ninety five percent) of each financial year(“Target Availability”).	</p>
                <p className='mb-4'>	Exclusions. The calculation of uptime will not include unavailability to the extent due to: (a) use of the Solution by the Client in a manner not authorized in these Terms of Use or any other applicable documents; (b) general internet problems, force majeure events or other factors outside of the Company’s reasonable control; (c) Clients’ equipment, software, network connections or other infrastructure; (d) third party systems, acts or omissions; or (e) any Scheduled Maintenance or reasonable emergency maintenance.	</p>
                <p className='mb-4'>	Remedy for Failure to Meet Target Availability. If there is a verified failure of services to meet Target Availability for two (2) consecutive months, then the Client shall have the right to terminate these Terms of Use by notifying the Company of such continuous unavailability after the end of the second such month. In such case of Target Availability, the Company will refund to Client any Fees as paid by the Client for the utilization of the Solution.	</p>
                <h5 className='text-xl font-medium pt-4 '>	THIRD PARTY APPLICATION	</h5>
                <p className='mb-4'>	Third party products, software, services, applications, including but not limited to Third Party Services, may be included with, or downloaded in the course of the Client availing the Solution. Notwithstanding anything to the contrary stated in these Terms of Use, the Company makes no representations whatsoever about any such third-party products, software, services and applications. Since the Company has no control over such products, software, services and applications, the Client acknowledges and agrees that the Company is not responsible for the availability of such products, software, services and applications and is not responsible or liable for any content, advertising, products, services, or other materials on or available from such third parties. The Client expressly acknowledges and agrees that use of such third-party products, software, services and applications is at the Client’s sole risk and that the entire risk of unsatisfactory quality, performance, accuracy and effort is with the Client and the Client hereby indemnifies, saves and holds harmless the Company from any and all such risks. The Client acknowledges and agrees that the use of any such third party products, software, services and applications is governed by such third party terms of use, license agreement, privacy policy, or other such agreement and that any information or personal data that the Client provides, whether knowingly or unknowingly, to such third parties, will be subject to such third party privacy policy, if such a policy exists and shall be incorporated herein by reference to the extent applicable under these Terms of Use.	</p>

                <h5 className='text-xl font-medium pt-4 '>	DATA PROTECTION & PRIVACY	</h5>
                <p className='mb-4'>	(i) Unless explicitly stated otherwise in these Terms of Use, the Client shall be responsible to ensure that relevant consents under Applicable Data Protection Laws have been obtained from the users and/or individuals/ data subjects. The Client hereby agrees and acknowledges that the Company shall have no liability towards any users arising as a result of the collection and processing of any Personal Data by the Client. 	</p>

                <p className='mb-4'>	(ii) The Client shall be responsible to maintain records of all consents collected as per the Applicable Data Protection Laws. The Client shall be liable to pay for any damages that the Company incurs due to inaccurate and/ or insufficient consents received from any users. 	</p>

                <p className='mb-4'>	(iii) The Company will not use Personal Data collected on behalf of the Client, received from the Client or its authorized personnel or otherwise processed on behalf of the Client for any purpose other than as necessary for the utilization of the Solution under these Terms of Use.	</p>

                <p className='mb-4'>	(iv) The Parties shall be compliant with the provisions of Applicable Data Privacy Laws and shall undertake reasonable security practices as may be prescribed under such Applicable Data Protection Law.	</p>

                <h5 className='text-xl font-medium pt-4 '>	DISCLAIMERS OF WARRANTIES	</h5>
                <p className='mb-4'>	(i) To the extent permitted by the Applicable Laws from time to time, the Company disclaims any and all representations and warranties that the Solution provided under these Terms of Use shall be uninterrupted, error-free and devoid of any bugs, viruses, bots or that the Solution shall be provided as timely and/or secure and/or on uninterrupted basis. 	</p>

                <p className='mb-4'>	(ii) The Company shall not be liable for any errors, omissions, interruptions, deletion of files or emails, loss of or damage to data, errors, defects, viruses, delays in operation or transmission, or any failure of performance, communication failure, data pilferage due to the use of any data or information in respect of the Solution. 	</p>

                <h5 className='text-xl font-medium pt-4 '>	INDEMNITY	</h5>
                <p className='mb-4'>	(i) Client Indemnity: The Client hereby agrees to indemnify, defend and hold harmless the Company, its Affiliates, directors, officers, agents, employees from and against any and all losses, liabilities, claims, damages, demands, suits, actions, proceedings, costs and expenses, incurred or suffered by the Company, in connection with or arising out of:	</p>

                <p className='mb-4'>	(a) Breach of or violation of or non-compliance with any of the obligation, warranties, representation, covenants and undertakings herein, by the Client or any of its representatives;	</p>

                <p className='mb-4'>	(b) infringement of any third party’s Intellectual Property Rights for availing the Solution from the Company.	</p>

                <p className='mb-4'>	(ii) Company’s Indemnity: The Company agrees to indemnify, defend and hold harmless the Client from and against any and all losses, liabilities, claims, damages, demands, suits, actions, proceedings, costs and expenses in connection with or arising out of any third party claims for providing Solution to the Client. 	</p>

                <h5 className='text-xl font-medium pt-4 '>	LIMITATION OF LIABILITY	</h5>
                <p className='mb-4'>	(i) Neither Party shall be liable to the other Party for any indirect, special, incidental, punitive or consequential loss or damages of any kind, or for any loss that could have been avoided by the use of reasonable diligence, arising in connection with the Terms of Use, even if the Party responsible for the damages has been advised or should be aware of the possibility of such damages. 	</p>

                <p className='mb-4'>	(ii) In no event shall the maximum aggregate liability of the Company under these Terms of Use to the Client whether arising from contract, indemnity, tort or otherwise, exceed the amount of the Fees payable by the Client to the Company in the twelve (12) months preceding the date on which the claim arose.	</p>

                <h5 className='text-xl font-medium pt-4 '>	INTELLECTUAL PROPERTY RIGHTS 	</h5>
                <p className='mb-4'>	All Intellectual Property Rights in and title to the Solution, the present or future modifications, Updates and Upgrades shall unconditionally remain under the exclusive ownership of the Company at all times. These Terms of Use does not and shall not transfer any ownership or proprietary interest in the Solution from the Company to the Client, except as may be otherwise expressly provided herein or as may be agreed in writing by and between the Parties. 	</p>
                <p className='mb-4'>	The Client understands and acknowledges that the License granted herein pertains to the Solution solely developed by the Company, and does not include a license to any third-party software or intellectual property. Unless otherwise expressly agreed upon, the Client shall be solely responsible for obtaining and maintaining such third-party software and Intellectual Property Rights, at its sole cost and expense. The Client shall have no rights whatsoever with respect to any other software or products developed by the Company, not expressly subscribed or licensed under these Terms of Use. In the event the Client subsequently desires to license or deal in any other manner with any other software developed by the Company, then the Parties will negotiate in good faith to determine the terms upon which such other software/ solution would be provided to the Client. 	</p>
                <p className='mb-4'>	The Client shall at no time, during the Term or after its termination, contest the validity or ownership of the Intellectual Property of the Company. The Client shall not use or register any trademark, design, product name or trade name which is confusingly similar to Company’s trademarks, product names or trade names.  	</p>
                <p className='mb-4'>	The Client agrees to promptly notify the Company of any claims, demands or notices arising in connection with the Intellectual Property of the Company. The Client shall provide necessary support, execute documents and do such acts and things as may be reasonably requested by the Company in this regard.	</p>
                <h5 className='text-xl font-medium pt-4 '>	INDEPENDENT CONTRACTOR	</h5>
                <p className='mb-4'>	The relationship between Parties shall be on principal-to-principal basis. Each Party is an independent contractor and is not a legal representative, partner or agent of the other Party. Neither Party shall have any right, power or authority, whether express or implied, to create any obligation on behalf of the other Party or bind the other Party.	</p>

                <h5 className='text-xl font-medium pt-4 '>	GOVERNING LAW, ARBITRATION AND JURISDICTION	</h5>
                <p className='mb-4'>	Any and all questions of enforceability and interpretation, which may arise under these Terms of Use, shall be determined and governed by and in accordance with the laws of India and subject to the arbitration clause as set out below, the courts located at Patna, Bihar shall have exclusive jurisdiction.	</p>
                <p className='mb-4'>	In the event of any dispute arising out of or in relation to these Terms of Use, each Party shall make efforts to resolve the same amicably through their respective representatives/ senior management of both the parties. In case the dispute is not resolved amicably within 30 (thirty) days of arising of such dispute, then the dispute shall be referred to arbitration before a sole arbitrator appointed jointly by both Parties, in accordance with the Arbitration and Conciliation Act, 1996 and any amendments thereafter. The venue of arbitration shall be Patna (Bihar). The language of the arbitration proceedings shall be English. 	</p>
                <p className='mb-4'>	Notwithstanding anything to the contrary, the Parties may agree to conduct the arbitration proceedings virtually through such video conferencing or other audio-visual means as may be mutually agreed between the Parties.	</p>
                <h5 className='text-xl font-medium pt-4 '>	PUBLICITY	</h5>
                <p className='mb-4'>	It is agreed between the Parties that the Company reserves the right to use certain details of the Client, including but not limited to the logo or name of the Client and any performance metrics obtained under these Terms of Use for its own marketing and advertising purposes. The Client hereby expressly consents to the usage of such details by the Company and provide the Company a perpetual, irrevocable, worldwide, royalty free license for the provision of such usage.	</p>

                <h5 className='text-xl font-medium pt-4 '>	FORCE MAJEURE	</h5>
                <p className='mb-4'>	Except for the duty to make payments hereunder when due, and the indemnification provisions under these Terms of Use, neither the Company nor the Client shall be liable by reason of any failure or delay in the performance of its obligations arising out of a Force Majeure Event. As used in these Terms of Use, “Force Majeure Event” shall mean: any act of God, act of nature or the elements, terrorism, insurrection, revolution or civil strife, piracy, civil war or hostile action, labor strikes, acts of public enemies, federal or state laws, rules and regulations of any governmental authorities having jurisdiction over the premises, inability to procure material, equipment, or necessary labor in the open market, acute and unusual labor, material, or equipment shortages, or any other causes (except financial) beyond the control of either Party. In the event, if one or more Force Majeure Events results in delay of 15 (fifteen) consecutive days in the performance of the obligations under these Terms of Use, the Parties shall mutually discuss to terminate these Terms of Use without any further liability. However, the Client shall be liable to pay the Company for the utilization of the Solution provided in accordance with these Terms of Use. 	</p>

                <h5 className='text-xl font-medium pt-4 '>	CONTACT DETAILS	</h5>
                <p className='mb-4'>	All communications or notices permitted or required to be given or served to the Company under these Terms of Use shall be in writing, shall be addressed to the Company as per the details set out below. These details may be updated from time to time by the Company and shall also be made available on the Solution. 	</p>

                <p className='mb-4'>	Email ID: info@xbot.webspecia.in	</p>

                <h5 className='text-xl font-medium pt-4 '>	SURVIVAL AND SEVERABILITY	</h5>
                <p className='mb-4'>	The rights and obligations arising out of these Terms of Use, which by their nature should survive or are expressly so stated herein, shall remain in full force and effect to the extent so specified, notwithstanding any expiry or termination of these Terms of Use. If any provision of these Terms of Use (or any portion thereof) is determined to be invalid or unenforceable the remaining provisions of these Terms of Use shall not be affected by such determination, shall be binding upon the parties and shall be enforceable.	</p>

                <h5 className='text-xl font-medium pt-4 '>	WAIVER	</h5>
                <p className='mb-4'>	Neither Party shall, by mere lapse of time, without giving notice thereof, be deemed to have waived any right or remedy arising hereunder or in connection with any breach or illegality involving the other Party. The waiver by either Party of any such right or remedy shall not be construed as a waiver of any other right or remedy or as a continuing waiver with respect to any similar, ongoing, or repeated circumstances.	</p>

                <h5 className='text-xl font-medium pt-4 '>	ASSIGNMENT	</h5>
                <p className='mb-4'>	The Company may assign these Terms of Use and/or part thereof at its discretion, at any time, to any of its Affiliates, group companies, holding company, subsidiary companies, third parties without any notice to the Client. However, the Client shall not assign these Terms of Use or any part thereof to any party without taking prior written consent of the Company.	</p>


            </div>

        </div>
    )
}

export default TermsCondition
