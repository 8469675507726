import React from 'react'
import HeroImg from './../TravelBot/media/hero.png'
import WhatsappHeroSlider from '../InnerTemplate/WhatsappHeroSlider'
import WhyDoYouNeed from '../InnerTemplate/WhyDoYouNeed'
import WhyImg from './../WhatsappChatbot/image/test1.png'
import Cta from '../InnerTemplate/Cta'
import WhatsappSolution from '../InnerTemplate/WhatsappSolution'
import sol1Img from './../TravelBot/media/sol1.png'
import sol2Img from './../TravelBot/media/sol2.png'
import sol3Img from './../TravelBot/media/sol3.png'
import sol4Img from './../TravelBot/media/sol4.png'
import Clients from '../Clients'
import CrossPlatform from '../CrossPlatform'
import Pricing from '../Pricing'
import { Helmet } from 'react-helmet'

function TravelBot() {
    return (
        <div className="pt-10">
            <Helmet>‍
                <title>WhatsApp Chatbot for Travel Business | Xbot by Webspecia</title>‍
                <meta name="description" content="Want to increase customer engagement and streamline your travel business operations? Xbot's WhatsApp Chatbot can help. With fast, automated responses to common customer questions, seamless booking and payment processes, and integration with your existing systems, our chatbot can help you improve your customer experience and increase sales. Get started with Xbot today and see the difference for yourself" />

            </Helmet>

            <div>
                <WhatsappHeroSlider
                    heroimg={HeroImg}
                    HeroHeading={'Create 5-star experiences with your Travel chatbot'}
                />
            </div>


            <div >
                <Clients title={`Try us, you're in good company`} />
            </div>
            <div>
                <WhyDoYouNeed
                    heading={'Why your Travel business needs an AI chatbot?'}
                    fOneHeading={'82%'}
                    fOneText={'Booking inquiries handled by travel chatbots'}
                    fTwoHeading={'79%'}
                    fTwoText={'Travel companies increased their revenue with chatbot'}
                    fThreeHeading={'30%'}
                    fThreeText={'Increase in direct bookings via chatbot'}
                    fFourHeading={'47%'}
                    fFourText={'Travellers redeemed offers sent on WhatsApp broadcasts'}
                    WhyImg={WhyImg}
                />
            </div>

            <div >
                <Cta />
            </div>
            <div>
                <WhatsappSolution
                    heading={`How Xbot can grow your Travel business?`}
                    sol1Title={`Confirm Instant bookings`}
                    sol1Desc={`Use the travel booking chatbot to automate your travel agency operations. Show all relevant travel information, display promotions and instantly confirm customer bookings!`}
                    sol1Img={sol1Img}

                    sol2Title={`Assist traveler's with instant information`}
                    sol2Desc={`Provide all round travel assistance by giving all travel and journey related information. List of documents, help plan itinerary, tourist information, things to do etc.`}
                    sol2Img={sol2Img}

                    sol3Title={`Shorten the sales cycle`}
                    sol3Desc={`Narrow the gap between awareness and purchase with highly personalised interactions with less human intervention that convert prospects into paying customers by using your travel chatbot for hotel booking and more!`}
                    sol3Img={sol3Img}

                    sol4Title={`Broadcast your offers on WhatsApp`}
                    sol4Desc={`Use a WhatsApp chatbot to share your offers & promotions with your targeted customers, and ensure higher success rates for your campaigns.`}
                    sol4Img={sol4Img}
                />
            </div>

            <div name="pricing" >
                <Pricing />
            </div>

            <div className=''>
                <Cta />
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />

            </div>

        </div>
    )
}

export default TravelBot
