import React from 'react'
import about from '../about-2.png'
import { Helmet } from 'react-helmet'

function AboutUs() {
    document.title = "About Us - Xbot : #1 AI Chatbot Builder, WhatsApp Business Bot";
    return (
        <div className='md:mt-20'>
            <>
                <Helmet>‍
                    <title>About Us - Xbot by Webspecia</title>‍
                    <meta name="description" content="Get to know the talented and experienced team at Xbot by Webspecia, your go-to provider for custom WhatsApp chat builder solutions. From developers to customer support, we have the expertise to help your business succeed with WhatsApp communication." />

                </Helmet>

            </>
            <div className='md:grid md:grid-cols-2 gap-2 md:mx-10 md:my-10 md:mt-10'>
                <div className='p-4 md:rounded-lg md:border md:border-gray-200 md:shadow-lg'>
                    < h2 className='py-4 text-3xl font-bold text-center' > About Us </h2 >
                    <p className='mb-2'>
                        omniXbot started with a simple thesis. Can we bring a low-friction approach via conversational automation (to capture business logic) and intelligence ( applied AI/NLP) to the rapidly evolving domain around chatbots?
                    </p>
                    <p className='mb-2'>
                        Buoyed by our early experience with key customers across a wide range of industries, we embarked as a young organization in 2022, with an intense desire to humanize customer engagement.
                    </p>
                </div>

                <div className='flex justify-center p-4'>
                    <img src={about} alt="about-xbot" className='h-80' />
                </div>

            </div>
        </div>
    )
}

export default AboutUs
