import React from 'react'
import HeroImg from './../EcommerceBot/media/header.png'
import WhatsappHeroSlider from '../InnerTemplate/WhatsappHeroSlider'
import WhyDoYouNeed from '../InnerTemplate/WhyDoYouNeed'
import WhyImg from './../WhatsappChatbot/image/test1.png'
import Cta from '../InnerTemplate/Cta'
import WhatsappSolution from '../InnerTemplate/WhatsappSolution'
import sol1Img from './../EcommerceBot/media/sol1.png'
import sol2Img from './../EcommerceBot/media/sol2.png'
import sol3Img from './../EcommerceBot/media/sol3.png'
import sol4Img from './../EcommerceBot/media/sol4.png'
import Clients from '../Clients'
import CrossPlatform from '../CrossPlatform'
import Pricing from '../Pricing'
import { Helmet } from 'react-helmet'

function EcomBot() {
    return (
        <div className="pt-10">
            <Helmet>‍
                <title>Boost Your Ecommerce Sales with a WhatsApp Chatbot | Xbot by Webspecia</title>‍
                <meta name="description" content="Improve customer service and streamline your operations with a WhatsApp chatbot from Xbot by Webspecia. Our chatbots can provide fast, automated responses to common customer questions, facilitate order tracking and updates, and provide product information. Integrate with your existing systems and see how a chatbot can increase your sales and improve the customer experience." />

            </Helmet>



            <div>
                <WhatsappHeroSlider
                    heroimg={HeroImg}
                    HeroHeading={'5x your business with WhatsApp Chatbot'}
                />
            </div>


            <div >
                <Clients title={`Try us, you're in good company`} />
            </div>
            <div>
                <WhyDoYouNeed
                    heading={'Why your Ecommerce business needs an AI chatbot?'}
                    fOneHeading={'35%'}
                    fOneText={'Reduction in cart abandonments'}
                    fTwoHeading={'40%'}
                    fTwoText={'Boost in conversion rates'}
                    fThreeHeading={'57%'}
                    fThreeText={'Automation in scheduling returns & refunds'}
                    fFourHeading={'34%'}
                    fFourText={'Repeat order rate with existing customers'}
                    WhyImg={WhyImg}
                />
            </div>
            <div >
                <Cta />
            </div>
            <div>
                <WhatsappSolution
                    heading={`How can Xbot grow your E-commerce business?`}
                    sol1Title={`Shorten the overall sales cycle`}
                    sol1Desc={`Use the eCommerce chatbot to narrow the gap between awareness and purchase with highly-personalized product recommendations and engagements that convert visitors into paying customers`}
                    sol1Img={sol1Img}

                    sol2Title={`Share notifications for order updates, payments, returns & refunds`}
                    sol2Desc={`Automate your post-purchase processes with the eCommerce chatbot. You can use WhatsApp message templates to share these updates`}
                    sol2Img={sol2Img}

                    sol3Title={`Showcase & sell your products directly on WhatsApp`}
                    sol3Desc={`Deliver an end-to-end shopping experience allowing the customers to check your products on WhatsApp, add them to the cart, & receive payments directly on WhatsApp`}
                    sol3Img={sol3Img}

                    sol4Title={`Run targeted marketing campaigns and generate better leads`}
                    sol4Desc={`Use the e-commerce chatbots to run full-fledged marketing campaigns across various channels. Use Conversational AI to target and engage with customers with personalization.`}
                    sol4Img={sol4Img}
                />
            </div>
            <div name="pricing" >
                <Pricing />
            </div>
            <div className=''>
                <Cta />
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />

            </div>
        </div>

    )
}

export default EcomBot
