import React from 'react'
import { Helmet } from 'react-helmet'

function Privacy() {
    document.title = "Privacy Policy - Xbot ";

    return (
        <div className='px-4 md:mt-20 md:mb-10 md:px-32'>
            <Helmet>‍
                <title>Privacy Policy | Xbot by Webspecia</title>‍
                <meta name="description" content="At Xbot by Webspecia, we are committed to protecting the privacy and security of our users. Our privacy policy explains how we collect, use, and protect personal information on our website and WhatsApp chatbot services." />

            </Helmet>
            <div>

                < h2 className='py-4 text-3xl font-bold ' > Privacy Policy </h2 >
                <p className='mb-'>
                    Thank you for being a part of the Avokis Technology Pvt. Ltd.! Your privacy is important to us. Please read the following statement to learn about our information gathering and dissemination practices.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    SCOPE:
                </h5>
                <p className='mb-4 pt-3'>
                    omnixbot is a Avokis Technology Pvt. Ltd. Company  (hereinafter referred to as “the Company”, “we”, “our”, “us”) inter alia, offers a suite of messaging software-as-a-service solutions through a single platform to enable its customer to manage communications with their users under the name and style of “omniXBot” (hereinafter referred to as “Solution”) as a service to its customers, in order to enhance their user support through quicker responses and more efficient internal processes. The Solution is committed to protecting and respecting your privacy.
                </p>
                <p className='mb-4'>
                    This policy (“Privacy Policy”), together with the terms and conditions, available at [https:xbot.webspecia.in/privacy-policy], and all other policies listed as part of the Solution, outlines the privacy practices of the Solution regarding collection, use and safeguard of your information through the Solution and the services offered thereupon.
                </p>
                <p className='mb-4'>
                    By using, registering or signing up for the Solution, you accept the terms of this Privacy Policy. If you do not accept any of these terms, your usage of the Solution will be restricted.
                </p>
                <p className='mb-4'>
                    Your clicking of the “I agree” button and submission of personal information or if you are a minor, the submission of your personal information either by your parents (or your legal guardian) or by you with the permission of your parents (or legal guardian) to the Solution will be deemed as your acceptance of this Privacy Policy and agree to be contacted by us to inform you about products and/or services and/or upcoming benefits offered by us or any of our affiliates or any other sub- domains.
                </p>
                <p className='mb-4'>
                    This Privacy Policy is an electronic record in terms of the Information Technology Act, 2000 (as amended/re-enacted from time to time) (“IT Act”) and rules thereunder, and, amongst others, is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011, which mandates for publishing of rules and regulations, privacy policy and terms of use for access or usage of the Solution. This Privacy Policy is an electronic record, generated by a computer system and does not require any physical or digital signatures.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    CHANGES TO THE PRIVACY POLICY:
                </h5>
                <p className='mb-4 pt-3'>
                    We will treat your information in accordance with applicable laws and regulations of India.
                    This Privacy Policy is subject to modification(s) based on business, legal or regulatory requirements and will be updated online from time to time. We will make all efforts to communicate any significant changes to this Privacy Policy to you. You are encouraged to periodically visit this page to review the Privacy Policy and any changes to it. Your continued participation in the Solution after any modification(s) to this Privacy Policy will be deemed as your acceptance to such modification(s).
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    PERSONAL INFORMATION WE COLLECT:
                </h5>
                <p className='mb-4 pt-3'>
                    “Personal information” is defined as information that can be used to identify you and may include details such as your name, age, gender, contact information and details, incoming/outgoing messages, business information, products and services you are interested in or require more information about. Insofar as sensitive personal information is concerned, it will carry the meaning as may be defined by applicable laws from time to time.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    We collect, use and share your personal information in the following manner:
                </h5>
                <h5 className='text-lg font-medium pt-4 '>
                    Collection:
                </h5>
                <p className='mb-4 pt-3'>
                    We may collect your personal information, whenever relevant, to provide you with the services and/or complete any transaction or provide any product you have requested or authorized under the Solution. You also consent to the collection of certain personal information in the course of your applying for the products and/or services with regards to the Solution.
                </p>
                <h5 className='text-lg font-medium pt-4 '>
                    Usage:
                </h5>
                <p className='mb-4 pt-3'>
                    We seek this personal information either to (a) validate and process your request for the usage of the Solution; or (b) assist the Company and/or any of its affiliates to handle and fulfill your orders and/or transactions, if any, or (c) to communicate details of any allied products and/or services and/or transaction to any third party; or (d) improve the quality of the Solution; or (e) assist you in determining the services/products best suited for your needs; or (f) facilitate our internal business operations, including the fulfilment of any legal and regulatory requirements; or (g) provide you with recommendation about services/products you may be interested in, based on your participation in the Solution; or (h) provide you with marketing communications and advertising that we believe may be of interest of you; or (i) resolve disputes or troubleshooting problems; or (j) detect and protect us against any error, fraud and other criminal activity; or (h) enforce our terms and conditions; or (i) as otherwise described to you at the time of collection.
                </p>
                <h5 className='text-lg font-medium pt-4 '>
                    Sharing:
                </h5>
                <p className='mb-4 pt-3'>
                    We may disclose your personal information to our affiliates when necessary (a) to provide the services on our behalf or on your behalf in relation to the Solution; or (b) to display advertising and promotional services; or (c) to provide search results and links (including paid listings and links); or (d) to provide efficient customer service. Please note that our entities and affiliates may market to you as a result of such sharing. If you wish to opt-out of any such sharing, do email us on hello@webspecia.in.
                </p>
                <p className='mb-4'>
                    We may disclose your personal information to any third parties. This disclosure may be required for us to provide you and such third parties to provide services under the Solution or for enhancing your experience, or to comply with our legal obligations.
                    We may also share your personal information with external organizations or individuals if we believe that such access, usage, preservation or disclosure of the personal information is reasonably necessary to:

                </p>
                <ul className='list-disc px-10' >
                    <li>
                        meet any applicable law, regulation, legal process or enforceable governmental request;
                    </li>
                    <li>
                        detect, prevent or otherwise address fraud, security or technical issues;
                    </li>
                    <li>
                        protect against any harm to rights, property or safety of our customers or the public, as required or permitted by applicable laws in India.
                    </li>
                </ul>
                <h5 className='text-xl font-medium pt-4 '>
                    NON-PERSONAL INFORMATION WE COLLECT:
                </h5>
                <p className='mb-4 pt-3'>
                    Non-personal information is defined as any information that does not personally identify you and may include unique system or hardware identifiers, system or application software, and peripherals.
                </p>
                <p className='mb-4'>
                    Any non-personal information, when clubbed with personal information shall be treated as personal information.
                </p>
                <p className='mb-4'>
                    The following is the manner in which we collect, use and share non-personal information.
                </p>
                <h5 className='text-lg font-medium pt-4 '>
                    Collection:
                </h5>
                <p className='mb-4 pt-3'>
                    You agree that the Solution may collect diagnostic, technical, usage related information, for the usage purposes described below, or communicated to you at the time of collection. This information, once collected, is anonymized in a manner that does not personally identify you.
                </p>
                <h5 className='text-lg font-medium pt-4 '>
                    Usage:
                </h5>
                <p className='mb-4 pt-3'>
                    The non-personal information is gathered periodically to provide and improve the Solution and services therein, facilitate the provision of software updates, product support and other services to you (if any) and to verify compliance with the terms of this Privacy Policy. As non-personal information does not personally identify you, we may use and disclose non-personal information at our discretion, subject to applicable laws.
                </p>
                <h5 className='text-lg font-medium pt-4 '>
                    Sharing:
                </h5>
                <p className='mb-4 pt-3'>
                    To enable the Solution partners and Third Party Service Providers to improve their software, hardware and services designed for use with the Solution, we may also provide any such partner or Third Party Service Providers with a subset of diagnostic information that is relevant to such partner’s or Third Party Service Provider’s software, hardware and/or services, as long as the diagnostic information is in a form that does not personally identify you.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    LOCATION SERVICES:
                </h5>
                <p className='mb-4 pt-3'>
                    The Solution, its licensees and agents, may access, collect and analyse your participation in the Solution and usage of the Solution therein and other information related to your location on a regular basis through Bluetooth and Wi-Fi signals and other technologies and data for the performance of the services provided by the Solution and to help improve the design, functionality, performance, and content of the Solution. In order to collect geo-location information, the location settings must be enabled on your device. Therefore, in some cases, the device may request that you enable the location settings.
                    Except in cases where any specific services requested by you require or involve personal identification, the Solution collects location data anonymously and in a form that does not personally identify you. By using the Solution, you agree and consent to transmission, collection, maintenance, processing and use of your location data and queries to provide and improve such services of the Solution.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    COOKIES:
                </h5>
                <p className='mb-4 pt-3'>
                    We place both permanent and temporary cookies in your device in relation to the Solution. Information collected by cookies and other technologies are treated as non-personal information. However, to the extent that IP addresses or similar identifiers are considered personal information by local law, we treat any information linked to such identifiers as personal information. Similarly, to the extent that non-personal information is combined with personal information, we will treat the combined information as personal information.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    OTHER INFORMATION THE SOLUTION COLLECTS:
                </h5>
                <p className='mb-4 pt-3'>
                    The Solution may also collect other information about you in ways that the Solution describe to you or otherwise with your consent. You can choose not to provide us with certain types of information, but doing so may affect your ability to avail and/or use some services.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    THIRD PARTY SERVICES, THIRD PARTY APPLICATIONS, WEBSITES & SERVICES:
                </h5>
                <p className='mb-4 pt-3'>
                    Third Party Services shall mean the third party services made available by the Company under the Solution including but not limited to WhatsApp business platforms and the term “Third Party Service Provider” shall mean the provider of such Third Party Services.
                </p>

                <p className='mb-4'>
                    The Solution may include links to other websites/applications or any of its sub domains or website/applications of any Third Party Service Providers or may display advertisements from such Third Party Service Providers and other content that links to Third Party Services. Such Third Party Services are governed by their respective privacy policies, which are out of our control. Once you leave our servers, use of any information you provide is governed by the privacy policy of the operators of the Third Party Services you are visiting and such policies may differ from this Privacy Policy. If you can’t find the privacy policy of any of the Third Party Service Providers, you must contact the relevant website /application of such Third Party Service Providers directly for more information. We shall not be responsible for the privacy practices or the content of such Third Party Services.
                </p>
                <p className='mb-4'>
                    We shall not be liable for the services provided by the Third Party Service Providers, on the Solution. You shall assume all risks arising out of or resulting from any transaction undertaken by you with such Third Party Service Providers on the Solution and you agree and acknowledge that the Company shall not be responsible or liable for any losses arising out of your usage of such Third Party Services.
                </p>
                <p className='mb-4'>
                    We may use third party advertising companies to serve ads when you visit the Solution.
                </p>
                <p className='mb-4'>
                    Please note that any Third Party Services you access as a part of the Solution, may also collect, use and share information about you and your usage. We cannot control how these Third Party Service Providers collect, use, share or secure this information. These Third Party Service Providers who collects, processes, shares, uses, retains your personal and/or usage related information or with whom such information is shared pursuant to contractual obligations shall be solely responsible for such usage and liability, if any.
                </p>

                <h5 className='text-xl font-medium pt-4 '>
                    ACCESS, CORRECTION AND DELETION:
                </h5>
                <p className='mb-4 pt-3'>
                    We strive hard to keep our records updated and accurate with your latest information. You shall be responsible to ensure that the information or data you provide from time to time is and shall be correct, current and updated and you have all the rights, permissions and consents to provide such information or data.
                </p>
                <p className='mb-4'>
                    You may note that deletion of certain information or withdrawal of consent may lead to cancellation of your access to the Solution or your access to certain features and services of the Solution. Additionally, we may not be able to process your request of correction, updating or deletion, in case the same is not supported by valid documents or data retention is required by the applicable law or law enforcement requests or under any judicial proceedings or it is extremely difficult to implement (such as requests related to backup copies or if a new system is required to process the request or change of technical design) or risks the privacy of other users.
                </p>

                <h5 className='text-xl font-medium pt-4 '>
                    PROCESSING OF THE INFORMATION
                </h5>
                <p className='mb-4 pt-3'>
                    We along with our affiliates or Third Party Service Providers with whom we have entered into contractual arrangements may also process any personal and/or usage related information collected from you for legitimate commercial purposes including providing the aforementioned services for the Solution, unless you explicitly opt out. Doing so may affect your ability to avail and/or use the Solution.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    WAIVER OF LIABILITY
                </h5>
                <p className='mb-4 pt-3'>
                    Notwithstanding anything contrary mentioned in this Privacy Policy, we shall not be liable for any indirect, incidental, consequential, special or exemplary damage(s) arising due to loss of data or use arising out of or in connection with your access or use of or inability to access or use the Solution including but not limited to any sub-domains or any Third Party Services.
                </p>
                <h5 className='text-xl font-medium pt-4 '>
                    QUERIES AND COMPLAINTS:
                </h5>
                <p className='mb-4 pt-3'>
                    We are committed to protect your personal information collected and processed by us and look forward to your continued support for the same. In case of any feedback or concern regarding protection of your personal information, or any privacy-related feedback or concerns you may contact the Data Protection officer Waqal Afzal at info[at]webspecia.in for any other clarification.


                </p>

            </div>

        </div>
    )
}

export default Privacy
