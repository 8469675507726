
import React, { useEffect, useState } from 'react';
// import { Link } from "react-scroll";
import logoBlack from '../xbot-logo.png'
import { FaAngleDown, FaBars, FaCalendarAlt, FaTimes, FaUserAlt } from 'react-icons/fa'
import { AiOutlineApi, AiOutlineBank, AiOutlineDesktop, AiOutlineFileDone, AiOutlineInstagram, AiOutlinePicCenter, AiOutlinePicLeft, AiOutlinePicRight, AiOutlineShoppingCart, AiOutlineWhatsApp, AiOutlineYoutube } from 'react-icons/ai';
import { MdEmojiTransportation, MdOutlineSupport, MdOutlineTravelExplore } from 'react-icons/md';
import { BsBuilding } from 'react-icons/bs';

function Navbar() {

    const [nav, setNav] = useState(false);
    const links = [
        {
            id: 1,
            title: 'Platforms',
            icon: <FaAngleDown className='ml-1' />,
            sublink: 'yes'
        },
        {
            id: 2,
            title: 'Solutions',
            icon: <FaAngleDown className='ml-1' />,
            sublink: 'yes'


        },
        {
            id: 3,
            title: 'WhatsApp Chatbot',
            link: 'whatsapp',
            sublink: 'no'
        },
        {
            id: 4,
            title: 'Pricing',
            link: 'pricing',
            sublink: 'no'
        },
        {
            id: 5,
            title: 'Why Xbot',
            icon: <FaAngleDown className='ml-1' />,
            sublink: 'yes'
        },
        {
            id: 6,
            title: 'Resources',
            icon: <FaAngleDown className='ml-1' />,
            sublink: 'yes'
        },

    ];

    const [selected, setSelected] = useState(1)

    const handleMouseEnter = (id) => {
        if (selected === id) {
            return setSelected(null)
        }
        setSelected(id)
        console.log(selected, 'is selected')
    }

    const handleMouseLeave = () => {
        setSelected(null)
    }


    useEffect(() => {
        setSelected(null)


    }, [])

    return (
        <nav className='bg-white px-2 sm:px-4 py-2.5 dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b shadow-md border-gray-200 dark:border-gray-600'>
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                <a href='/'><img src={logoBlack} alt="logoBlack" className='cursor-pointer mx-4 md:mx-10 w-28' /></a>

                <div className="hidden w-full md:block md:w-auto" id="navbar-default">

                    <ul className='flex space-x-4 items-center'>

                        {links.map(({ id, link, title, icon, sublink }) => (

                            <li key={id}>


                                <a onMouseEnter={() => handleMouseEnter(id)} onMouseLeave={handleMouseLeave} href={link} smooth duration={500} className='capitalize cursor-pointer py-2 text-sm font-medium hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-1 relative '>
                                    <div className='inline-flex items-center'>
                                        <div>{title}</div>
                                        <div>{icon}</div>
                                    </div>



                                    <div className={`${selected === id && id === 1 && sublink === 'yes' ? 'contents' : 'hidden'} absolute flex justify-center`}>
                                        <div class={`absolute  w-full border border-gray-200  rounded-lg shadow-lg md:w-72`}>
                                            <div class="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
                                                <h5 className='block px-4 text-sm py-2 mb-2 font-bold text-gray-500'>WHAT WE PROVIDE</h5>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/whatsapp"> <AiOutlineWhatsApp className='w-8 text-xl text-green-600' /> Whatsapp Chatbot</a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/instagram-facebook"><AiOutlineInstagram className='w-8 text-xl text-pink-600' />  Instagram / FB Chatbot</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${selected === id && id === 2 && sublink === 'yes' ? 'contents' : 'hidden'} absolute flex justify-center`}>
                                        <div class={`absolute  w-full border border-gray-200  rounded-lg shadow-lg md:w-64`}>
                                            <div class="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
                                                <h5 className='block px-4 text-sm py-2 mb-2 font-bold text-gray-500'>INDUSTRIES TRANSFORMED</h5>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/ecommerce"><AiOutlineShoppingCart className='w-8 text-xl text-pink-600' />  E-Commerce & Retail</a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/logistics"><MdEmojiTransportation className='w-8 text-xl text-blue-600' /> Logistics</a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/education"><AiOutlineDesktop className='w-8 text-xl text-orange-600' /> EdTech</a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/travel"><MdOutlineTravelExplore className='w-8 text-xl text-red-600' /> Travel & Hospitality</a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/insurance"><AiOutlineBank className='w-8 text-xl text-green-600' /> Insurance & Banking</a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/government"><AiOutlineFileDone className='w-8 text-xl text-indigo-600' /> Government</a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/realestate"><BsBuilding className='w-8 text-xl text-teal-600' /> Real Estate</a>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${selected === id && id === 5 && sublink === 'yes' ? 'contents' : 'hidden'} absolute flex justify-center`}>
                                        <div class={`absolute  w-full border border-gray-200  rounded-lg shadow-lg md:w-64`}>
                                            <div class="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/xbot-vs-wati"><AiOutlinePicLeft className='w-8 text-xl text-red-600' /> Xbot vs Wati</a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/xbot-vs-yellow-ai"><AiOutlinePicCenter className='w-8 text-xl text-orange-600' /> Xbot vs Yellow.Ai</a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/xbot-vs-haptic"><AiOutlinePicRight className='w-8 text-xl text-pink-600' /> Xbot vs Haptic</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${selected === id && id === 6 && sublink === 'yes' ? 'contents' : 'hidden'} absolute flex justify-center`}>
                                        <div class={`absolute  w-full border border-gray-200  rounded-lg shadow-lg md:w-64`}>
                                            <div class="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="https://www.youtube.com/user/WebSpecia"><AiOutlineYoutube className='w-8 text-xl text-red-600' /> YouTube</a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="https://help.webspecia.in/portal/en/home"><MdOutlineSupport className='w-8 text-xl text-indigo-600' /> Help & Support </a>
                                                <a class="inline-flex text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="https://chat-xbot.webspecia.in/api"><AiOutlineApi className='w-8 text-xl text-orange-600' /> API </a>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </li>

                        ))}


                        <li>
                            <div className="inline-flex rounded-md shadow-sm">
                                <a href="https://chat-xbot.webspecia.in/login" type='button' className='inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-slate-900 rounded-l-lg border border-r-0 border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 '>
                                    <FaUserAlt className="mr-2 w-3 h-3 fill-current" />            Login
                                </a>

                                <a href="demo" type='button' className='cursor-pointer inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-slate-900 rounded-r-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 '>
                                    <FaCalendarAlt className="mr-2 w-3 h-3 fill-current" /> Book a demo</a>
                            </div>
                        </li>

                    </ul>
                </div>
                <div onClick={() => setNav(!nav)} className='cursor-pointer inline-flex items-center p-2 ml-3 text-sm z-10 text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200  '>
                    {nav ? <FaTimes size={25} /> : <FaBars size={25} />}
                </div>
                {nav &&
                    <div className=" w-full text-center md:block md:w-auto lg:hidden" id="navbar-default">
                        <ul className="capitalize flex flex-col p-0 mt-0 bg-gray-50 rounded-lg border border-gray-100 ">
                            {links.map(({ id, link, title, icon, sublink }) =>

                                <li key={id}>
                                    <a href={link} smooth duration={500} onClick={() => handleMouseEnter(id)} className="block py-2 pr-4 pl-3 border-b-[1px] border-gray-200 hover:bg-gray-100">

                                        <div className='inline-flex items-center'>
                                            <div>{title}</div>
                                            <div>{icon}</div>
                                        </div>




                                        <div className={`${selected === id && id === 1 && sublink === 'yes' ? 'contents' : 'hidden'} absolute flex justify-center`}>
                                            <div class={`  w-full   rounded-lg shadow-lg md:w-72`}>
                                                <div class="px-2 py-2  shadow dark-mode:bg-gray-800">
                                                    <h5 className='block px-4 text-sm py-2 mb-2 font-bold text-gray-500'>WHAT WE PROVIDE</h5>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/whatsapp">Whatsapp Chatbot</a>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/instagram-facebook">Instagram / FB Chatbot</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${selected === id && id === 2 && sublink === 'yes' ? 'contents' : 'hidden'} absolute flex justify-center`}>
                                            <div class={` w-full   rounded-lg shadow-lg md:w-72`}>
                                                <div class="px-2 py-2  shadow dark-mode:bg-gray-800">
                                                    <h5 className='block px-4 text-sm py-2 mb-2 font-bold text-gray-500'>INDUSTRIES TRANSFORMED</h5>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/ecommerce">E-Commerce & Retail</a>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/logistics">Logistics</a>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/education">Education</a>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/travel">Travel & Hospitality</a>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/insurance">Insurance & Banking</a>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/government">Government</a>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/realestate">Real Estate</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`${selected === id && id === 5 && sublink === 'yes' ? 'contents' : 'hidden'} absolute flex justify-center`}>
                                            <div class={`w-full   rounded-lg shadow-lg md:w-72`}>
                                                <div class="px-2 py-2  shadow dark-mode:bg-gray-800">
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/xbot-vs-wati">Xbot vs Wati</a>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/xbot-vs-yellow-ai">Xbot vs Yellow.Ai</a>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="/xbot-vs-haptic">Xbot vs Haptic</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${selected === id && id === 6 && sublink === 'yes' ? 'contents' : 'hidden'} absolute flex justify-center`}>
                                            <div class={`w-full   rounded-lg shadow-lg md:w-72`}>
                                                <div class="px-2 py-2  shadow dark-mode:bg-gray-800">
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="https://www.youtube.com/user/WebSpecia">YouTube</a>
                                                    <a class="block text-gray-900 hover:text-blue-900 px-4 py-2 mt-2 text-sm bg-transparent rounded-lg  md:mt-0 " href="https://help.webspecia.in/portal/en/home">Help & Support </a>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                }
                {/* platforms */}



            </div>

        </nav >
    )
}

export default Navbar