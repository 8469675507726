import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BsCheck2Circle } from 'react-icons/bs'
import { Helmet } from 'react-helmet'

function XbotVsWati() {
    document.title = "Xbot Vs WATI";

    return (
        <div className='mx-4 md:py-6'>
            <Helmet>‍
                <title>Xbot Vs WATI | Xbot by Webspecia</title>‍
                <meta name="description" content="Are you trying to decide between Xbot and Wati for your WhatsApp chatbot needs? In this comparison, we'll take a look at the key features and benefits of each platform, as well as their pricing and support options. Whether you're a small business owner or a large enterprise, this guide will help you make an informed decision about which chatbot is the best fit for your business goals." />

            </Helmet>
            < h2 className='py-4 text-3xl font-bold text-center' > Xbot Vs WATI </h2 >
            <div className='px-4 md:mx-32 md:px-6 md:py-2 rounded-md shadow-md border border-gray-200'>

                <p className='py-2'>
                    Xbot & Wati, both are leading platform for WhatsApp marketing. Xbot is mainly designed for a unified platform for multiple channels whereas Wati serves only WhatsApp Chats.
                </p >
                <p className='py-2'>
                    Xbot allows you to merge facebook messanger, instagram, WhatsApp, Viber, Twitter, Google my Business and many other messaging platforms into an omni panel where your agents can interact and answer the queries of your customers, live.
                </p>
                <p className='py-2'>
                    Here is a brief comparison that explains why Xbot is powerful, reliable & cost effective as compared to Wati.
                </p>
            </div>
            <div className=''>

                <div className="my-4 overflow-x-auto relative md:mx-32 md:my-8 rounded-md shadow-md border border-gray-200">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-white uppercase bg-gray-600 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Features
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Xbot
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Wati
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    Incoming WebHook
                                </th>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>


                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    Extra Agent
                                </th>
                                <td className="py-4 px-6">
                                    INR 999/month
                                </td>
                                <td className="py-4 px-6">
                                    INR 2250/month
                                </td>

                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    Plan Starts from
                                </th>
                                <td className="py-4 px-6">
                                    INR 399/month for 200 contacts
                                </td>
                                <td className="py-4 px-6">
                                    INR 2900/month
                                </td>

                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    Multi Channel Support
                                </th>
                                <td className="py-4 px-6">
                                    Supports multiple channels
                                </td>
                                <td className="py-4 px-6">
                                    Only WhatsApp
                                </td>

                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    Consultation+Support
                                </th>
                                <td className="py-4 px-6">
                                    Free
                                </td>
                                <td className="py-4 px-6">
                                    Paid
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default XbotVsWati