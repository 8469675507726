import React from 'react'
import HeroImg from './../InstagramBot/media/hero.gif'
import WhatsappHeroSlider from '../InnerTemplate/WhatsappHeroSlider'
import WhyDoYouNeed from '../InnerTemplate/WhyDoYouNeed'
import WhyImg from './../WhatsappChatbot/image/test1.png'
import Cta from '../InnerTemplate/Cta'
import WhatsappSolution from '../InnerTemplate/WhatsappSolution'
import sol1Img from './../InstagramBot/media/sol1.gif'
import sol2Img from './../InstagramBot/media/sol2.gif'
import sol3Img from './../InstagramBot/media/sol3.png'
import sol4Img from './../InstagramBot/media/sol4.gif'
import Clients from '../Clients'
import CrossPlatform from '../CrossPlatform'
import Pricing from '../Pricing'
import { Helmet } from 'react-helmet'

function InstagramBot() {
    return (
        <div className="pt-10">
            <Helmet>‍
                <title>Instagram Chatbot | Xbot by Webspecia</title>‍
                <meta name="description" content="Improve your Instagram presence and grow your business with an Instagram chatbot from Xbot. Our chatbots can help you manage and respond to customer inquiries, deliver personalized recommendations, and even facilitate sales and bookings. Get started with Xbot today and see the benefits for yourself." />

            </Helmet>
            <div>
                <WhatsappHeroSlider
                    heroimg={HeroImg}
                    HeroHeading={'The next big thing, chatbots for Instagram / Facebook'}
                />
            </div>


            <div >
                <Clients title={`Try us, you're in good company`} />
            </div>
            <div>
                <WhyDoYouNeed
                    heading={'Why you need Instagram / Facebook automation for your business?'}
                    fOneHeading={'1BN+'}
                    fOneText={'People use Instagram / Facebook everyday'}
                    fTwoHeading={'83%'}
                    fTwoText={'of users make purchase decisions over the app'}
                    fThreeHeading={'81%'}
                    fThreeText={'of users, use Instagram / Facebook to research products'}
                    fFourHeading={'130MN'}
                    fFourText={'Users tap on shopping posts every month'}
                    WhyImg={WhyImg}
                />
            </div>

            <div >
                <Cta />
            </div>
            <div>
                <WhatsappSolution
                    heading={`How Xbot’s Instagram / Facebook chatbots can help you`}
                    sol1Title={`Engage & grow your followers`}
                    sol1Desc={`Respond to every user comment by sending automated replies based on the post context`}
                    sol1Img={sol1Img}

                    sol2Title={`Reply to every mention!`}
                    sol2Desc={`Automate replies to story mentions with Engati’s Instagram / Facebook automation`}
                    sol2Img={sol2Img}

                    sol3Title={`Never miss out on a DM!`}
                    sol3Desc={`Use chatbots to automate DM replies and answer complex queries with Live Chat`}
                    sol3Img={sol3Img}

                    sol4Title={`Answer product queries`}
                    sol4Desc={`Ready to comments about prices and specifications with private replies`}
                    sol4Img={sol4Img}
                />
            </div>
            <div name="pricing" >
                <Pricing />
            </div>
            <div className=''>
                <Cta />
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />

            </div>

        </div>
    )
}

export default InstagramBot
