import React from 'react'
import HeroImg from './../InsuranceBot/media/hero.png'
import WhatsappHeroSlider from '../InnerTemplate/WhatsappHeroSlider'
import WhyDoYouNeed from '../InnerTemplate/WhyDoYouNeed'
import WhyImg from './../WhatsappChatbot/image/test1.png'
import Cta from '../InnerTemplate/Cta'
import WhatsappSolution from '../InnerTemplate/WhatsappSolution'
import sol1Img from './../InsuranceBot/media/sol1.png'
import sol2Img from './../InsuranceBot/media/sol2.png'
import sol3Img from './../InsuranceBot/media/sol3.png'
import sol4Img from './../InsuranceBot/media/sol4.png'
import Clients from '../Clients'
import CrossPlatform from '../CrossPlatform'
import Pricing from '../Pricing'
import { Helmet } from 'react-helmet'

function InsuranceBot() {
    return (
        <div className="pt-10">
            <Helmet>‍
                <title>WhatsApp Chatbot for Insurance & Banking | Xbot by Webspecia</title>‍
                <meta name="description" content="Are you an insurance or banking business looking to increase customer engagement and streamline operations? Xbot by Webspecia can help with our WhatsApp chatbot solutions. Our chatbots can provide fast, automated responses to common customer questions, facilitate payments and account updates, and provide personalized recommendations. Plus, our chatbots can be integrated with your existing systems to streamline your workflow. See how Xbot by Webspecia can help your insurance or banking business grow with WhatsApp chatbots." />

            </Helmet>
            <div>
                <WhatsappHeroSlider
                    heroimg={HeroImg}
                    HeroHeading={'Sell 3x policies with chatbots for Insurance'}
                />
            </div>


            <div >
                <Clients title={`Try us, you're in good company`} />
            </div>
            <div>
                <WhyDoYouNeed
                    heading={'Why your Insurance business needs an AI chatbot?'}
                    fOneHeading={'35%'}
                    fOneText={'Reduction in cart abandonments'}
                    fTwoHeading={'40%'}
                    fTwoText={'Boost in conversion rates'}
                    fThreeHeading={'57%'}
                    fThreeText={'Automation in scheduling returns & refunds'}
                    fFourHeading={'34%'}
                    fFourText={'Repeat order rate with existing customers'}
                    WhyImg={WhyImg}
                />
            </div>

            <div >
                <Cta />
            </div>
            <div>
                <WhatsappSolution
                    heading={`How can Xbot grow your Banking & Insurance business?`}
                    sol1Title={`Handle complex queries with simple solutions via a Banking bot`}
                    sol1Desc={`Deliver personalized advice by empowering agents with a fully-integrated Banking chatbot, serving as a virtual advisor. Load policies and documents to the platform to enable the discovery of suitable solutions.`}
                    sol1Img={sol1Img}

                    sol2Title={`Ensure accessibility by enabling omnichannel streams on your profile`}
                    sol2Desc={`Optimize your communication with customers with omnipresence using by enabling your banking chatbot across various channels`}
                    sol2Img={sol2Img}

                    sol3Title={`Support clients with finance needs across their lifecycle.`}
                    sol3Desc={`Make your clients' lives easier. Help them make transactions, apply for loans, buy or renew Insurance policies, and even make claims with digital frictionless engagement using banking bots.`}
                    sol3Img={sol3Img}

                    sol4Title={`Reach out to your customers through WhatsApp`}
                    sol4Desc={`Xbot Chatbot enables you to schedule live sessions between teachers and students to solve complex queries.`}
                    sol4Img={sol4Img}
                />
            </div>
            <div name="pricing" >
                <Pricing />
            </div>
            <div className=''>
                <Cta />
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />

            </div>

        </div>
    )
}

export default InsuranceBot
