import React from 'react'
import { Link } from 'react-scroll'


function Cta() {
    return (
        <div className='md:px-48 px-2 py-10'>
            {/* <div className=' rounded-2xl border border-gray-200 bg-white shadow-md p-10 md:grid md:grid-cols-4 md:gap-2'>
                <div className='col-span-3'>
                    <h2 className='text-xl text-center md:text-left md:mb-0 mb-4 md:text-3xl font-medium md:px-2'> Automate your business on WhatsApp with Xbot</h2>
                </div>
                <div className='p-3 border-2 border-dashed border-blue-800/40 rounded-full'>
                    <Link to='demo'>
                        <button className='bg-blue-800 text-white tracking-widest text-sm w-full rounded-full p-3'>
                            SCHEDULE A DEMO
                        </button>
                    </Link>
                </div>
            </div> */}

            <div className=' rounded-2xl border border-gray-700 bg-gray-900 text-white shadow-md p-10 md:grid md:grid-cols-4 md:gap-2'>
                <div className='col-span-3'>
                    <h2 className='text-xl text-center md:text-left md:mb-0 mb-4 md:text-3xl font-medium md:px-2'> Automate your business on WhatsApp with Xbot</h2>
                </div>
                <div className='p-3 border-2 border-dashed border-blue-100 rounded-full'>
                    <Link to='demo'>
                        <button className='bg-blue-800 text-white tracking-widest text-sm w-full rounded-full p-3'>
                            SCHEDULE A DEMO
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Cta
