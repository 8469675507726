import React from 'react'
import Cta from '../InnerTemplate/Cta'
import WhatsappHeroSlider from '../InnerTemplate/WhatsappHeroSlider'
import WhatsappSolution from '../InnerTemplate/WhatsappSolution'
import WhyDoYouNeed from '../InnerTemplate/WhyDoYouNeed'
import HeroImg from './../../Whatsapp-Chatbot.gif'
import WhyImg from '../WhatsappChatbot/image/test1.png'
import sol1Img from '../WhatsappChatbot/image/test2.png'
import sol2Img from '../WhatsappChatbot/image/test3.gif'
import sol3Img from '../WhatsappChatbot/image/test4.gif'
import sol4Img from '../WhatsappChatbot/image/test5.png'
import Clients from '../Clients'
import CrossPlatform from '../CrossPlatform'
import Pricing from '../Pricing'
import { Helmet } from 'react-helmet'

function WhatsappChatBot() {
    return (
        <div className="pt-10">
            <Helmet>‍
                <title>WhatsApp Chatbots for Your Business | Xbot</title>‍
                <meta name="description" content="Improve customer communication, streamline operations, and increase sales with a WhatsApp chatbot from Xbot. Learn more about the benefits of WhatsApp chatbots for your business on our website." />

            </Helmet>

            <div>
                <WhatsappHeroSlider
                    heroimg={HeroImg}
                    HeroHeading={'5x your business with WhatsApp Chatbot'}
                />
            </div>

            <div >
                <Clients title={`Leading brands that use Xbot’s WhatsApp chatbots`} />
            </div>
            <div>
                <WhyDoYouNeed
                    heading={'Why do you need WhatsApp automation & engagement for your business?'}
                    fOneHeading={'62%'}
                    fOneText={'Average growth in conversions on marketing campaigns'}
                    fTwoHeading={'97%'}
                    fTwoText={'Open rate for notifications and promotions on WhatsApp'}
                    fThreeHeading={'54%'}
                    fThreeText={'Average spike in resolved queries with WhatsApp automation'}
                    fFourHeading={'89%'}
                    fFourText={'Higher count of leads captured with WhatsApp chatbots'}
                    WhyImg={WhyImg}
                />
            </div>
            <div className=''>
                <Cta />
            </div>
            <div>
                <WhatsappSolution
                    heading={`How Xbot's WhatsApp solutions can help grow your business`}
                    sol1Title={`Leverage social commerce to sell your products directly on WhatsApp`}
                    sol1Desc={`Deliver an end-to-end shopping experience allowing the customers to check your products and add them to cart directly on WhatsApp, without taking them to any other link`}
                    sol1Img={sol1Img}

                    sol2Title={`Broadcast notifications to your customers & automate post-purchase queries`}
                    sol2Desc={`Use the WhatsApp business API to share updates and notifications with ready-made WhatsApp message templates that can be created in minutes`}
                    sol2Img={sol2Img}

                    sol3Title={`Use WhatsApp message templates to share payment confirmations, order status & more`}
                    sol3Desc={`Announce your sales and discount campaigns to your customers over WhatsApp, allowing you to drive more conversions, earn more revenue, and gain more value from your AI chatbot`}
                    sol3Img={sol3Img}

                    sol4Title={`Drive WhatsApp marketing campaigns with interactive buttons`}
                    sol4Desc={`You can send WhatsApp broadcasts to acquire more customers at a lower price than most other channels because of the higher engagement & conversion rates`}
                    sol4Img={sol4Img}
                />
            </div>
            <div name="pricing" >
                <Pricing />
            </div>
            <div className=''>
                <Cta />
            </div>
            <div className='bg-gray-100'>
                <CrossPlatform />
            </div>

        </div >
    )
}

export default WhatsappChatBot
