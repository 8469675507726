import React, { useState } from 'react'
import { IoMdContacts } from 'react-icons/io'
import { MdSupportAgent } from 'react-icons/md'
import { SiChatbot } from 'react-icons/si'

function Addons() {
    const addonBtn = [
        {
            id: 1,
            icon: <IoMdContacts className='w-8 h-8 mr-3' />,
            btnText: 'Contact Addon'
        },
        {
            id: 2,
            icon: <SiChatbot className='w-8 h-8 mr-3' />,
            btnText: 'Bot Addon'
        },
        {
            id: 3,
            icon: <MdSupportAgent className='w-8 h-8 mr-3' />,
            btnText: 'Live Agent Addon'
        },
    ]

    const addonData = [
        {
            id: 1,
            price: '₹999',
            desc: 'for every 1000 addon bot user'
        },
        {
            id: 2,
            price: '₹999',
            desc: 'for every addon bot '
        },
        {
            id: 3,
            price: '₹999',
            desc: 'for every live agent addon '
        },
    ]

    const [selected, setSelected] = useState(1)

    const toggle = (id) => {
        if (selected === id) {
            return setSelected(id)
        }
        setSelected(id)
        console.log(selected, 'is selected')
    }


    return (
        <>
            <div className='hidden md:contents   py-2'>
                <div className='grid grid-cols-4 border shadow-md border-gray-200 rounded-lg mx-48'>
                    <div className='col-span-2 w-auto p-3 border-r border-gray-200 bg-gray-100'>
                        <div className="px-4 md:my-8">
                            <span className=" text-3xl font-bold text-center">Flexible add-on</span>
                            <p>Add extra subscribers, members or bots on top of your business plan.Scale your chat marketing with our flexible add-ons.</p>
                        </div>
                    </div>
                    <div className=" text-gray-900 border-r border-gray-200">
                        {addonBtn.map(({ id, icon, btnText }) => (
                            <button key={id} onClick={() => toggle(id)} type="button" className="inline-flex relative items-center py-4 px-4 w-full text-md font-medium  border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white">
                                {icon}
                                {btnText}
                            </button>

                        ))}
                    </div>




                    <div className='  border-r border-gray-200 py-6'>
                        {addonData.map(({ id, price, desc }) => (
                            <div key={id}>
                                {selected === id && <div>
                                    <div className="flex justify-center items-baseline mt-8">
                                        <span className="mr-2 text-xl md:text-4xl font-extrabold">{price}</span>
                                        <span className="text-gray-500 dark:text-gray-400" >/month</span>
                                    </div>
                                    <p className='text-center px-1.5 text-gray-500'>{desc}</p>
                                </div>}

                            </div>
                        ))}

                    </div>

                </div>
            </div >
            <div className=' md:hidden'>
                <div className="px-4 mb-4">
                    <span className=" text-3xl font-bold text-center ">Flexible add-on</span>
                    {/* <p>Add extra subscribers, members or bots on top of your business plan.Scale your chat marketing with our flexible add-ons.</p> */}
                </div>
                <div className=' rounded-lg border border-gray-200 shadow-md'>
                    <div className='grid grid-cols-2'>
                        <div className='p-2 border-b  border-gray-200 flex items-center'> <IoMdContacts className='w-4 h-4 mr-1' /> <span className='text-sm'>Contact Addon</span> </div>
                        <div className='p-2 border-b border-l border-gray-200 flex text-center'>  <span className='text-sm'>₹999 /month for every 1000 addon bot user</span> </div>
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='p-2 border-b  border-gray-200 flex items-center'> <SiChatbot className='w-4 h-4 mr-1' /> <span className='text-sm'>Bot Addon</span> </div>
                        <div className='p-2 border-b border-l border-gray-200 flex text-center'>  <span className='text-sm'>₹999 /month for every addon bot</span> </div>
                    </div>
                    <div className='grid grid-cols-2'>
                        <div className='p-2 border-b  border-gray-200 flex items-center'> <MdSupportAgent className='w-4 h-4 mr-1' /> <span className='text-sm'>Agent Addon</span> </div>
                        <div className='p-2 border-b border-l border-gray-200 flex text-center'>  <span className='text-sm'>₹999 /month for every live agent addon</span> </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Addons