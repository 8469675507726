import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { BsCheck2Circle } from 'react-icons/bs'
import { Helmet } from 'react-helmet'

function XbotVsYellow() {
    document.title = "Xbot Vs Yellow.Ai";

    return (
        <div>
            <Helmet>‍
                <title>Xbot Vs Yellow.Ai | Xbot by Webspecia</title>‍
                <meta name="description" content="Confused about which WhatsApp chatbot provider to choose? Our comparison of Xbot and Yellow.AI will help you decide. Learn about the features, pricing, and customer support of both companies and make an informed decision." />

            </Helmet>
            <div className='mx-4 my-4 py-4 md:py-6'>
                < h2 className='py-4 text-3xl font-bold text-center' > Xbot Vs Yellow.Ai </h2 >
                <div className='px-4 md:mx-32 md:px-6 md:py-2 rounded-md shadow-md border border-gray-200'>

                    <p className='py-2'>
                        As compared to Yellow, Xbot pricing is better and transparent. There are no hidden charges and you can easily scale up the usage without worrying much about pricing.
                    </p >
                    <p className='py-2'>
                        Xbot allows you to merge facebook messanger, instagram, WhatsApp, Viber, Twitter, Google my Business and many other messaging platforms into an omni panel where your agents can interact and answer the queries of your customers, live.
                    </p>
                    <p className='py-2'>
                        Here is a brief comparison that explains why Xbot is powerful, reliable & cost effective as compared to Yellow.
                    </p>
                </div>
                <div className=''>

                    <div className="my-4 overflow-x-auto relative md:mx-32 md:my-8 rounded-md shadow-md border border-gray-200">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-white uppercase bg-gray-600 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="py-3 px-6">
                                        Features
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Xbot
                                    </th>
                                    <th scope="col" className="py-3 px-6">
                                        Yellow.AI
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Incoming WebHook
                                    </th>
                                    <td className="py-4 px-6">
                                        <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                    </td>
                                    <td className="py-4 px-6">
                                        <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                    </td>


                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Extra Agent
                                    </th>
                                    <td className="py-4 px-6">
                                        INR 999/month
                                    </td>
                                    <td className="py-4 px-6">
                                        <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                    </td>

                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Plan Starts from
                                    </th>
                                    <td className="py-4 px-6">
                                        INR 399/month for 200 contacts
                                    </td>
                                    <td className="py-4 px-6">
                                        INR 25,000-1,00,000 depending on usage
                                    </td>

                                </tr>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Free Trial
                                    </th>
                                    <td className="py-4 px-6">
                                        <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                    </td>
                                    <td className="py-4 px-6">
                                        <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                    </td>

                                </tr>
                                <tr className="bg-white dark:bg-gray-800">
                                    <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        Consultation+Support
                                    </th>
                                    <td className="py-4 px-6">
                                        Free
                                    </td>
                                    <td className="py-4 px-6">
                                        <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default XbotVsYellow
