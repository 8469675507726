import React from 'react'
// import { div } from 'react-router-dom'

function Seamless() {
    return (
        <>

            <div className='grid md:grid-cols-2 md:gap-3'>
                <div className="block p-6 mb-4 md:mb-0 rounded-lg border border-gray-200 shadow-md max-w-sm bg-blue-50 hover:shadow-lg transition ease-in-out delay-50 duration-300 md:hover:-translate-y-3 md:hover:-translate-x-3">
                    <div to="card2" className='inline-flex items-center'>
                        <h5 className="mb-2 text-xl text-gray-900 font-bold tracking-light" > Multiple Agents</h5>
                    </div>
                    <p className='mb-3 font-normal text-gray-700'> With Xbot, you can have multiple chat agents </p>

                </div>
                <div className="block p-6 mb-4 md:mb-0 rounded-lg border border-gray-200 shadow-md max-w-sm bg-white transition ease-in-out delay-50 duration-300 md:hover:-translate-y-3 md:hover:translate-x-3">
                    <div to="card2" className='inline-flex items-center'>
                        <h5 className="mb-2 text-xl text-gray-900 font-bold tracking-light" > Lead qualification</h5></div>
                    <p className='mb-3 font-normal text-gray-700'> Lead qualification & segregation using bot replies </p>

                </div>
                <div className="block p-6 mb-4 md:mb-0 rounded-lg border border-gray-200 shadow-md max-w-sm bg-white transition ease-in-out delay-50 duration-300 md:hover:translate-y-3 md:hover:-translate-x-3">
                    <div to="card2" className='inline-flex items-center'>
                        <h5 className="mb-2 text-xl text-gray-900 font-bold tracking-light" > Drag & drop</h5></div>
                    <p className='mb-3 font-normal text-gray-700'>Simple drag and drop interface to build chatbots. </p>

                </div>
                <div className="block p-6 mb-4 md:mb-0 rounded-lg border border-gray-200 shadow-md max-w-sm bg-white transition ease-in-out delay-50 duration-300 md:hover:translate-y-3 md:hover:translate-x-3">
                    <div to="card2" className='inline-flex items-center'>
                        <h5 className="mb-2 text-xl text-gray-900 font-bold tracking-light" > Powerful Integration</h5></div>
                    <p className='mb-3 font-normal text-gray-700'>Powerful Integration with Shopify/WooCommerce </p>

                </div>


            </div>
        </>

    )
}

export default Seamless