import React from 'react'
import promo from '../images/features/promo.png'
import convoSale from '../images/features/convo-sales.png'
import convoSupport from '../images/features/convo-support.png'
import trans from '../images/features/trans.png'



function Features() {

    const featureData = [
        {
            id: 1,
            title: "Promotional Messaging",
            content: "Target specific interests with Broadcasts, enroll new contacts in drip campaigns or trigger event-based promotions via API",
            cardImg: promo
        },
        {
            id: 2,
            title: "Conversational Sales",
            content: "Trade-in personal messengers for business accounts, increase sales visibility, auto-qualify leads & sync with your CRM.",
            cardImg: convoSale
        },
        {
            id: 3,
            title: "Conversational Support",
            content: "Use automated messaging to identify customer issues, then route to agents according to skill, function, language, shift & more",
            cardImg: convoSupport
        },
        {
            id: 4,
            title: "Transactional Notifications",
            content: "Send bulk notifications like emergency broadcasts or downtime alerts directly from the platform or trigger them via API",
            cardImg: trans
        },

    ]

    return (
        <div className='p-10'>
            <h2 className='text-3xl font-bold text-center'>One Messaging Platform for Marketing, Sales, Support & Engineering</h2>
            <p className='py-1 text-center font-medium text-gray-600'>No more juggling channels between teams & platforms. One set of channels, one platform.</p>
            {/* <div className="flex space-x-3 md:px-16 md:py-10"> */}
            <div className="grid md:grid-cols-4 md:gap-3 md:mx-10 md:pt-10 justify-center">
                {featureData.map(({ id, title, content, cardImg }) => (

                    <div key={id} className='transition ease-in-out delay-50 duration-300 hover:-translate-y-3 mb-4 md:mb-0'>
                        <div className="rounded-lg border border-gray-200 shadow-md max-w-xs bg-white ">
                            <div>
                                <img src={cardImg} alt="img-card" className='rounded-t-lg' />
                            </div>
                            <div className='p-5'>
                                <div>
                                    <h5 className='mb-2 text-2xl text-gray-900 font-medium tracking-light'> {title} </h5>
                                </div>
                                <p className='mb-3 font-normal text-gray-700'>{content}</p>
                            </div>
                        </div>
                    </div>
                ))}



            </div>

        </div>
    )
}

export default Features