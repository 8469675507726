import React from 'react'
import arregala from '../images/clients/arregala.png'
import avn from '../images/clients/avn.png'
import cymbal from '../images/clients/cymbal.png'
import kidlling from '../images/clients/kidling_logo.png'
import pravaas from '../images/clients/pravaas.png'
import analytix from '../images/clients/analytix.png'
import kubbaba from '../images/clients/kubbaba.png'
import juicetime from '../images/clients/juicetime.png'
import ear from '../images/clients/ear.png'
import madesay from '../images/clients/madesay.png'

function Clients(props) {
    const clientData = [
        {
            id: 1,
            imgSrc: arregala,
        },
        {
            id: 2,
            imgSrc: avn,
        },
        {
            id: 3,
            imgSrc: cymbal,
        },
        {
            id: 4,
            imgSrc: kidlling,
        },
        {
            id: 5,
            imgSrc: juicetime,
        },




    ]
    const client2Data = [
        {
            id: 1,
            imgSrc: kubbaba,
        },
        {
            id: 2,
            imgSrc: madesay,
        },
        {
            id: 3,
            imgSrc: ear,
        },
        {
            id: 4,
            imgSrc: analytix,
        },
        {
            id: 5,
            imgSrc: pravaas,
        },



    ]
    return (
        <div className='bg-gray-100 py-3'>
            <h2 className='text-2xl font-medium text-center'>{props.title}</h2>
            <ul className='grid grid-cols-5 gap-2 p-2 md:pt-6 md:px-10 md:flex md:space-x-12 justify-center'>

                {clientData.map(({ id, imgSrc }) => (
                    <li key={id}>
                        <img src={imgSrc} alt="whatsapp-integration-with-zoho" className='w-36' />
                    </li>

                ))}



            </ul>
            <ul className='grid grid-cols-5 gap-2 p-2 md:pt-6 md:px-10 md:flex md:space-x-12 justify-center'>

                {client2Data.map(({ id, imgSrc }) => (
                    <li key={id}>
                        <img src={imgSrc} alt="whatsapp-integration-with-zoho" className='w-36' />
                    </li>

                ))}



            </ul>

        </div>
    )
}

export default Clients