import React from 'react'
import zoho from '../integrations/zoho.png'
import zapier from '../integrations/zapier.png'
import slack from '../integrations/slack.png'
import shopify from '../integrations/shopify.png'
import pabbly from '../integrations/pabbly.png'
import gsheet from '../integrations/gsheet.png'
import gmail from '../integrations/gmail.png'
import dialog from '../integrations/dialog360.png'



function Integration() {
    const intData = [
        {
            id: 1,
            imgSrc: zoho,
        },
        {
            id: 2,
            imgSrc: shopify,
        },
        {
            id: 3,
            imgSrc: gmail,
        },
        {
            id: 4,
            imgSrc: gsheet,
        },
        {
            id: 5,
            imgSrc: pabbly,
        },
        {
            id: 6,
            imgSrc: zapier,
        },
        {
            id: 7,
            imgSrc: dialog,
        },
        {
            id: 8,
            imgSrc: slack,
        },

    ]
    return (
        <div>
            <ul className='grid grid-cols-4 ml-8 md:ml-0 md:pt-6  md:flex md:space-x-10 md:justify-center'>

                {intData.map(({ id, imgSrc }) => (
                    <li key={id}>
                        <img src={imgSrc} alt="whatsapp-integration-with-zoho" className='w-20' />
                    </li>

                ))}



            </ul>
        </div>
    )
}

export default Integration