import React, { useState, useEffect } from 'react'
import { SiChatbot, SiGooglesheets, SiWoocommerce } from 'react-icons/si'
import { IoMdContacts } from 'react-icons/io'
import { AiFillWechat, AiOutlineCloseCircle, AiOutlineGoogle, AiOutlineWechat } from 'react-icons/ai'
import { MdSupportAgent } from 'react-icons/md'
import { TiFlowMerge } from 'react-icons/ti'
import { BsCalendarDay, BsCheck2Circle, BsFillCloudLightningRainFill, BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { TbBoxMultiple1 } from 'react-icons/tb'
import { FaCcStripe, FaFacebookMessenger, FaLine, FaPaypal, FaShopify, FaTelegram, FaViber } from 'react-icons/fa'
import { Link } from 'react-scroll'
import Addons from './Addons'
import AddonIndia from './AddonIndia'

function Pricing() {

    const [show, setShow] = useState(false)
    const [details, setDetails] = useState(null);
    const handleShowHide = () => {

        setShow(!show);
    }

    useEffect(() => {
        fetch(
            "https://geolocation-db.com/json/89eb4d70-4cbe-11ed-a0f2-51b843ebe8d7"
        )
            .then(response => response.json())
            .then(data => setDetails(data));


    }, [])




    return (
        <>

            <div className='p-10 md:py-10'>
                < h2 className='text-3xl font-bold text-center' > Pricing that scales with your business.</h2 >
                <p className='py-3 md:py-1 text-center font-medium text-gray-600'>Choose a plan that suits your business</p>
                <div className="flex space-x-3 md:px-16 md:py-10">
                    <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-12 lg:space-y-0">
                        {/* <!-- Pricing Card --> */}
                        {details && details.country_code !== 'IN' &&
                            <>
                                <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                                    <h3 className="mb-4 text-2xl font-semibold">Starter</h3>
                                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best option for personal use & testing purpose.</p>
                                    <div className="flex justify-center items-baseline my-8">
                                        <span className="mr-2 text-5xl font-extrabold">$5</span>
                                        <span className="text-gray-500 dark:text-gray-400">/month</span>
                                    </div>
                                    {/* <!-- List --> */}
                                    <ul className="mb-8 space-y-4 text-left">
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <SiChatbot className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of Chatbots :<span className="font-semibold"> 1 Bot</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <IoMdContacts className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of contacts :<span className="font-semibold"> 200 Contacts</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <AiFillWechat className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Conversations :<span className="font-semibold"> Unlimited</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <MdSupportAgent className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Live Chat Agent :<span className="font-semibold"> 1 Agent</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <TiFlowMerge className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Pro Features Trail: <span className="font-semibold">14 Days</span></span>
                                        </li>
                                    </ul>
                                    <a href="https://omnixbot.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Basic-Plan-USD-Monthly" target="_blank" rel="noreferrer" className="text-white bg-slate-900 hover:bg-salte-900 focus:ring-4 focus:ring-salte-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                                </div>
                                {/* <!-- Pricing Card --> */}
                                <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-blue-600 shadow-md dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                                    <span className='mx-24 bg-blue-100 text-blue-800 text-sm font-normal px-2 mb-2 py-0.5 rounded'> Popular</span>
                                    <h3 className="mb-4 text-2xl font-semibold">Business</h3>
                                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Relevant for multiple users, extended & premium support.</p>
                                    <div className="flex justify-center items-baseline my-8">
                                        <span className="mr-2 text-5xl font-extrabold">$29</span>
                                        <span className="text-gray-500 dark:text-gray-400" >/month</span>
                                    </div>


                                    {/* <!-- List --> */}
                                    <ul className="mb-8 space-y-4 text-left">

                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <SiChatbot className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of Chatbots :<span className="font-semibold"> 1 Bot</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <IoMdContacts className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of contacts :<span className="font-semibold"> 1000 Contacts</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <AiFillWechat className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Conversations :<span className="font-semibold"> Unlimited</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <MdSupportAgent className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Live Chat Agent :<span className="font-semibold"> 5 Agents</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <TiFlowMerge className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Pro Features : <span className="font-semibold"> Full Access</span></span>
                                        </li>
                                    </ul>
                                    <a href="https://omnixbot.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=new_business_plan-USD-Monthly" target="_blank" rel="noreferrer" className="text-white bg-blue-800 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                                </div>
                                {/* <!-- Pricing Card --> */}
                                <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                                    <h3 className="mb-4 text-2xl font-semibold">Business Plus</h3>
                                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best for large scale uses and to reach more contacts.</p>
                                    <div className="flex justify-center items-baseline my-8">
                                        <span className="mr-2 text-5xl font-extrabold">$39</span>
                                        <span className="text-gray-500 dark:text-gray-400">/month</span>
                                    </div>
                                    {/* <!-- List --> */}
                                    <ul className="mb-8 space-y-4 text-left">

                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <SiChatbot className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of Chatbots :<span className="font-semibold"> 1 Bot</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <IoMdContacts className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of contacts :<span className="font-semibold"> 3000 Contacts</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <AiFillWechat className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Conversations :<span className="font-semibold"> Unlimited</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <MdSupportAgent className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Live Chat Agent :<span className="font-semibold"> 5 Agents</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <TiFlowMerge className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Pro Features : <span className="font-semibold"> Full Access</span></span>
                                        </li>
                                    </ul>
                                    <a href="https://omnixbot.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Business-Plus-USD-Monthly" target="_blank" rel="noreferrer" className="text-white bg-slate-900 hover:bg-slate-700 focus:ring-4 focus:ring-slate-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                                </div>
                            </>
                        }

                        {details && details.country_code === 'IN' &&
                            <>
                                <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                                    <h3 className="mb-4 text-2xl font-semibold">Starter</h3>
                                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best option for personal use & testing purpose.</p>
                                    <div className="flex justify-center items-baseline my-8">
                                        <span className="mr-2 text-5xl font-extrabold">₹399</span>
                                        <span className="text-gray-500 dark:text-gray-400">/month</span>
                                    </div>
                                    {/* <!-- List --> */}
                                    <ul className="mb-8 space-y-4 text-left">
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <SiChatbot className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of Chatbots :<span className="font-semibold"> 1 Bot</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <IoMdContacts className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of contacts :<span className="font-semibold"> 200 Contacts</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <AiFillWechat className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Conversations :<span className="font-semibold"> Unlimited</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <MdSupportAgent className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Live Chat Agent :<span className="font-semibold"> 1 Agent</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <TiFlowMerge className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Pro Features Trail: <span className="font-semibold">14 Days</span></span>
                                        </li>
                                    </ul>
                                    <a href="https://omnixbot.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Basic-Plan-INR-Monthly" target="_blank" rel="noreferrer" className="text-white bg-slate-900 hover:bg-salte-900 focus:ring-4 focus:ring-salte-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                                </div>
                                {/* <!-- Pricing Card --> */}
                                <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-blue-600 shadow-md dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                                    <span className='mx-24 bg-blue-100 text-blue-800 text-sm font-normal px-2 mb-2 py-0.5 rounded'> Popular</span>
                                    <h3 className="mb-4 text-2xl font-semibold">Business</h3>
                                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Relevant for multiple users, extended & premium support.</p>
                                    <div className="flex justify-center items-baseline my-8">
                                        <span className="mr-2 text-5xl font-extrabold">₹2299</span>
                                        <span className="text-gray-500 dark:text-gray-400" >/month</span>
                                    </div>


                                    {/* <!-- List --> */}
                                    <ul className="mb-8 space-y-4 text-left">

                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <SiChatbot className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of Chatbots :<span className="font-semibold"> 1 Bot</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <IoMdContacts className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of contacts :<span className="font-semibold"> 1000 Contacts</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <AiFillWechat className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Conversations :<span className="font-semibold"> Unlimited</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <MdSupportAgent className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Live Chat Agent :<span className="font-semibold"> 5 Agents</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <TiFlowMerge className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Pro Features : <span className="font-semibold"> Full Access</span></span>
                                        </li>
                                    </ul>
                                    <a href="https://omnixbot.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=new_business_plan-INR-Monthly" target="_blank" rel="noreferrer" className="text-white bg-blue-800 hover:bg-blue-700 focus:ring-4 focus:ring-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                                </div>
                                {/* <!-- Pricing Card --> */}
                                <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                                    <h3 className="mb-4 text-2xl font-semibold">Business Plus</h3>
                                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best for large scale uses and to reach more contacts.</p>
                                    <div className="flex justify-center items-baseline my-8">
                                        <span className="mr-2 text-5xl font-extrabold">₹3099</span>
                                        <span className="text-gray-500 dark:text-gray-400">/month</span>
                                    </div>
                                    {/* <!-- List --> */}
                                    <ul className="mb-8 space-y-4 text-left">

                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <SiChatbot className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of Chatbots :<span className="font-semibold"> 1 Bot</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <IoMdContacts className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>No. of contacts :<span className="font-semibold"> 3000 Contacts</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <AiFillWechat className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Conversations :<span className="font-semibold"> Unlimited</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <MdSupportAgent className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Live Chat Agent :<span className="font-semibold"> 5 Agents</span></span>
                                        </li>
                                        <li className="flex items-center space-x-3">
                                            {/* <!-- Icon --> */}
                                            <TiFlowMerge className='flex-shrink-0 w-5 h-5 text-blue-500 ' />
                                            <span>Pro Features : <span className="font-semibold"> Full Access</span></span>
                                        </li>
                                    </ul>
                                    <a href="https://omnixbot.chargebee.com/hosted_pages/checkout?subscription_items[item_price_id][0]=Business-Plus-INR-Monthly" target="_blank" rel="noreferrer" className="text-white bg-slate-900 hover:bg-slate-700 focus:ring-4 focus:ring-slate-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
                                </div>

                            </>}






                    </div>
                </div>


                <div className='text-center font-medium text-lg text-blue-800 py-4 md:py-0'>
                    <span onClick={handleShowHide} className=' cursor-pointer'>Show Detailed Plan Comparision</span>
                </div>
                <div name="backtoshow" className="overflow-x-auto relative md:px-20">
                    <table className={`${show ? 'content' : 'hidden'} w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-10 `}>
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Features
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Starter
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Business
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Business Plus
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <SiChatbot className='flex-shrink-0 w-5 h-5 mr-2 text-blue-500 ' />
                                    <span>No. of Chatbots </span>
                                </th>
                                <td className="py-4 px-6">
                                    1 Bot
                                </td>
                                <td className="py-4 px-6">
                                    1 Bot
                                </td>
                                <td className="py-4 px-6">
                                    1 Bot
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <IoMdContacts className='flex-shrink-0 w-5 h-5 mr-2 text-blue-500 ' />
                                    <span>No. of contacts </span>
                                </th>
                                <td className="py-4 px-6">
                                    200 Contacts
                                </td>
                                <td className="py-4 px-6">
                                    1000 Contacts
                                </td>
                                <td className="py-4 px-6">
                                    3000 Contacts
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <AiFillWechat className='flex-shrink-0 w-5 h-5 mr-2 text-blue-500 ' />
                                    <span>Unlimited Conversations </span>
                                </th>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <MdSupportAgent className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Live Chat Agent </span>
                                </th>
                                <td className="py-4 px-6">
                                    1 Agent
                                </td>
                                <td className="py-4 px-6">
                                    5 Agents
                                </td>
                                <td className="py-4 px-6">
                                    5 Agents
                                </td>
                            </tr></tbody>
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Platforms
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Starter
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Business
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Business Plus
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <TbBoxMultiple1 className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>OmniChannel </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>

                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <BsWhatsapp className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Whatsapp Cloud API </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <FaFacebookMessenger className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Facebook Messenger Bot </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <BsInstagram className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Instagram Messenger Bot </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <AiOutlineGoogle className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Google Maps Business Bot </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <FaTelegram className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Telegram Bot </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <AiOutlineWechat className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>WeChat Bot </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <FaLine className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Line Bot </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <FaViber className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Viber Bot </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                        </tbody>
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Integration
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Starter
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Business
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Business Plus
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <FaCcStripe className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Stripe Payment </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <FaPaypal className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Paypal </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <BsFillCloudLightningRainFill className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Cloudinary </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <FaShopify className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Shopify </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <SiWoocommerce className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>WooCommerce </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <BsCalendarDay className='flex-shrink-0 w-5 h-5 text-blue-500 mr-2 ' />
                                    <span>Calendly </span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="flex py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    <SiGooglesheets className='flex-shrink-0 w-5 h-5 mr-2 text-blue-500 ' />
                                    <span>Google Sheet</span>
                                </th>
                                <td className="py-4 px-6">
                                    <AiOutlineCloseCircle className='w-5 h-5 text-red-500' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                                <td className="py-4 px-6">
                                    <BsCheck2Circle className='w-5 h-5 text-blue-800' />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='text-center py-6'>
                        <Link to="backtoshow" onClick={handleShowHide} className={`${show ? 'content' : 'hidden'} text-center font-medium text-lg py-6 text-blue-800 cursor-pointer`}>Hide Detailed Plan Comparision</Link>
                    </div>
                </div>
                {details && details.country_code !== 'IN' &&
                    <Addons />
                }

                {details && details.country_code === 'IN' &&
                    <AddonIndia />
                }

            </div >
        </>
    )
}

export default Pricing