import React from 'react'
import DemoForm from '../DemoForm'



function WhatsappHeroSlider(props) {
    return (
        <>
            <div className=''>
                {/* <div className='grid md:grid-cols-2 gap-12 md:px-48 '> */}
                <div className='md:flex md:justify-between md:px-36 '>
                    <div className='flex justify-center p-2 items-center'>
                        <img src={props.heroimg} alt="whatsapp-chatbot" className='md:-mt-8 md:pt-4 max-h-[300px] md:max-h-[400px]  ' />
                    </div>

                    <div className='' name="demo">
                        <h2 className='text-3xl font-bold text-center'>{props.HeroHeading}</h2>
                        <div>
                            <DemoForm />
                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}

export default WhatsappHeroSlider
