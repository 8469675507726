import React from 'react'
import { FaPhoneAlt } from 'react-icons/fa'
import { MdEmail, MdLocationOn } from 'react-icons/md'
import DemoForm from './DemoForm';
import { Helmet } from 'react-helmet'

function ContactUs() {


    return (
        <div className='md:mt-20 md:mb-10'>
            <Helmet>‍
                <title>Contact the Xbot by Webspecia team for expert support</title>‍
                <meta name="description" content="Need help with your WhatsApp chatbot? Contact the Xbot by Webspecia team for support and expert guidance. We are here to help your business grow with our chatbot solutions." />

            </Helmet>
            <div className='md:grid md:grid-cols-3 md:gap-10 md:mx-10'>

                <div className=' md:rounded-md md:border md:border-gray-200 md:shadow-lg bg-gray-200'>
                    <div className='py-6 px-6 md:px-10 '>
                        <span>Let's Talk</span>
                        < h2 className='py-4 text-3xl font-bold ' > Contact Us </h2 >
                        <div className='flex  items-center mb-8'>
                            <MdEmail className='rounded-full bg-gray-900  text-white w-16 h-16 p-2 mr-6' />
                            <div className=''>
                                <h5 className='text-lg font-medium'>Email: </h5>
                                <span>info@webspecia.in</span>
                            </div>

                        </div>
                        <div className='flex  items-center mb-8'>
                            <FaPhoneAlt className='rounded-full bg-gray-900  text-white w-16 h-16 p-2 mr-6' />
                            <div className=''>
                                <h5 className='text-lg font-medium'>Phone: </h5>
                                <a href="tel:+919760341277">+91-9760-341-277</a>
                            </div>

                        </div>
                        <div className='flex  items-center mb-4'>
                            <MdLocationOn className='rounded-full bg-gray-900  text-white w-16 h-16 p-2 mr-6' />
                            <div className=''>
                                <h5 className='text-lg font-medium'>Address: </h5>
                                <span>86, Mayur Vihar, New Delhi</span>
                            </div>

                        </div>

                    </div>

                </div>
                <div className='md:col-span-2 rounded-lg border border-gray-200 shadow-lg'>
                    <h2 className='text-3xl font-bold text-blue-800 text-center pt-4'>GET IN TOUCH</h2>
                    <p className='text-center text-sm pt-1'>Fill The Form Below</p>
                    <DemoForm />

                </div>
            </div>
        </div>
    )
}

export default ContactUs
