import React from 'react'
import { Link } from 'react-scroll'

function WhatsappSolution(props) {
    return (
        <div>
            <div className='py-8 mt-10 text-center'>
                <h2 className='text-2xl md:text-3xl md:font-bold font-medium px-2'> {props.heading}</h2>
            </div>
            <div>
                {/* Levarage social */}
                <div className='md:grid md:grid-cols-2 md:gap-10 px-4 md:px-52 py-10  text-center md:text-left'>
                    <div className=' md:flex md:flex-col md:justify-center'>
                        <h3 className='md:text-2xl md:font-semibold text-xl font-semibold mb-6'>
                            {props.sol1Title}
                        </h3>
                        <p className='mb-6 text-gray-600 text-sm'>{props.sol1Desc}</p>
                        <Link to='demo' className="flex justify-center items-center md:justify-start mb-6 md:mb-0">
                            <button className='border border-blue-800 text-blue-800 tracking-widest text-sm  rounded-full p-3'>
                                SCHEDULE A DEMO
                            </button>
                        </Link>
                    </div>
                    <div className='flex justify-center items-center'>
                        <img src={props.sol1Img} alt="" className='max-h-[400px]' />
                    </div>

                </div>
                {/* Broadcast notification */}
                <div className='flex flex-col-reverse md:grid md:grid-cols-2 md:gap-10 px-4 md:px-52 py-10 text-center md:text-left'>
                    <div className='flex justify-center items-center'>
                        <img src={props.sol2Img} alt="" className='max-h-[400px]' />
                    </div>
                    <div div className='md:flex md:flex-col md:justify-center'>
                        <h3 className='md:text-2xl md:font-semibold text-xl font-semibold mb-6'>
                            {props.sol2Title}
                        </h3>
                        <p className='mb-6 text-gray-600 text-sm'>
                            {props.sol2Desc}
                        </p>
                        <Link to='demo' className="flex justify-center items-center md:justify-start mb-6 md:mb-0">
                            <button className='border border-blue-800 text-blue-800 tracking-widest text-sm  rounded-full p-3'>
                                SCHEDULE A DEMO
                            </button>
                        </Link>
                    </div>


                </div>
                {/* Whatsapp Message template */}
                <div className='md:grid md:grid-cols-2 md:gap-10 px-4 md:px-52 py-10 text-center md:text-left'>
                    <div div className='md:flex md:flex-col md:justify-center'>
                        <h3 className='md:text-2xl md:font-semibold text-xl font-semibold mb-6'>
                            {props.sol3Title}
                        </h3>
                        <p className='mb-6 text-gray-600 text-sm'>
                            {props.sol3Desc}
                        </p>
                        <Link to='demo' className="flex justify-center items-center md:justify-start mb-6 md:mb-0">
                            <button className='border border-blue-800 text-blue-800 tracking-widest text-sm  rounded-full p-3'>
                                SCHEDULE A DEMO
                            </button>
                        </Link>
                    </div>
                    <div className='flex justify-center items-center'>
                        <img src={props.sol3Img} alt="" className='max-h-[400px]' />
                    </div>

                </div>
                {/* Whatsapp marketing */}
                <div className='flex flex-col-reverse md:grid md:grid-cols-2 md:gap-10 px-4 md:px-52 py-10 text-center md:text-left  '>
                    <div className='flex justify-center items-center '>
                        <img src={props.sol4Img} alt="" className='max-h-[400px]' />
                    </div>
                    <div div className='md:flex md:flex-col md:justify-center'>
                        <h3 className='md:text-2xl md:font-semibold text-xl font-semibold mb-6'>
                            {props.sol4Title}
                        </h3>
                        <p className='mb-6 text-gray-600 text-sm'>
                            {props.sol4Desc}
                        </p>
                        <Link to='demo' className="flex justify-center items-center md:justify-start mb-6 md:mb-0">
                            <button className='border border-blue-800 text-blue-800 tracking-widest text-sm  rounded-full p-3'>
                                SCHEDULE A DEMO
                            </button>
                        </Link>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default WhatsappSolution
