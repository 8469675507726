import React from 'react'

function WhyDoYouNeed(props) {
    return (
        <div className='md:mt-6'>
            <div className='flex justify-center md:px-48 px-2 '>
                <h2 className='text-3xl font-bold text-center py-8'>
                    {props.heading}
                </h2>
            </div>
            <div className='md:grid md:grid-cols-3 md:gap-4 md:mx-20 px-5 '>
                <div className='md:col-span-2   my-auto'>
                    <div className='md:grid md:grid-cols-2 md:gap-2 '>
                        <div className="block p-6 mb-4 md:mb-0 rounded-lg border border-gray-200 shadow-md max-w-sm bg-blue-50 hover:shadow-lg transition ease-in-out delay-50 duration-300 md:hover:-translate-y-3 md:hover:-translate-x-3">
                            <h5 className="mb-2 text-5xl text-center text-blue-800 font-bold " > {props.fOneHeading}</h5>
                            <p className='mb-3 text-center font-normal text-gray-700'>{props.fOneText} </p>

                        </div>
                        <div className="block p-6 mb-4 md:mb-0 rounded-lg border border-gray-200 shadow-md max-w-sm  hover:shadow-lg transition ease-in-out delay-50 duration-300 md:hover:-translate-y-3 md:hover:translate-x-3">
                            <h5 className="mb-2 text-5xl text-center text-blue-800 font-bold " > {props.fTwoHeading}</h5>
                            <p className='mb-3 text-center font-normal text-gray-700'>{props.fTwoText}</p>

                        </div>
                        <div className="block p-6 mb-4 md:mb-0 rounded-lg border border-gray-200 shadow-md max-w-sm  hover:shadow-lg transition ease-in-out delay-50 duration-300 md:hover:translate-y-3 md:hover:-translate-x-3">
                            <h5 className="mb-2 text-5xl text-center text-blue-800 font-bold " > {props.fThreeHeading}</h5>
                            <p className='mb-3 text-center font-normal text-gray-700'>{props.fThreeText} </p>

                        </div>
                        <div className="block p-6 mb-4 md:mb-0 rounded-lg border border-gray-200 shadow-md max-w-sm  hover:shadow-lg transition ease-in-out delay-50 duration-300 md:hover:translate-y-3 md:hover:translate-x-3">
                            <h5 className="mb-2 text-5xl text-center text-blue-800 font-bold " > {props.fFourHeading}</h5>
                            <p className='mb-3 text-center font-normal text-gray-700'>{props.fFourText} </p>

                        </div>


                    </div>
                </div>
                <div className='flex justify-center'>
                    <img src={props.WhyImg} alt="" />
                </div>
            </div>
        </div>
    )
}

export default WhyDoYouNeed
