import React, { useState } from 'react';
import { Link } from "react-router-dom";
import logoBlack from '../xbot-logo.png'
import { FaBars, FaCalendarAlt, FaTimes, FaUserAlt } from 'react-icons/fa'

function Navbar2() {
    const [nav, setNav] = useState(false);
    const links = [
        {
            id: 1,
            link: '/xbot-vs-wati',
            title: 'Xbot vs Wati'
        },
        {
            id: 2,
            link: '/xbot-vs-yellow-ai',
            title: 'Xbot vs Yellow.Ai'

        },
        {
            id: 3,
            link: '/xbot-vs-haptic',
            title: 'Xbot vs Haptic'
        },


    ];
    return (
        <nav className='bg-white px-2 sm:px-4 py-2.5 dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600'>
            <div className="container flex flex-wrap justify-between items-center mx-auto">
                <Link to='/'><img src={logoBlack} alt="logoBlack" className='cursor-pointer mx-4 md:mx-10 w-28' /></Link>

                <div className="hidden w-full md:block md:w-auto" id="navbar-default">

                    <ul className='flex space-x-4 items-center'>

                        {links.map(({ id, link, title }) => (

                            <li key={id}>

                                <Link to={link} smooth duration={500} className=' cursor-pointer py-2 text-lg font-medium hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-1'>{title}</Link>
                            </li>

                        ))}


                        <li>
                            <div className="inline-flex rounded-md shadow-sm">
                                <a href="https://chat-xbot.webspecia.in/login" type='button' className='inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-slate-900 rounded-l-lg border border-r-0 border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 '>
                                    <FaUserAlt className="mr-2 w-3 h-3 fill-current" />            Login
                                </a>

                                <a href="https://booking.webspecia.in/" type='button' className='inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-slate-900 rounded-r-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 '>
                                    <FaCalendarAlt className="mr-2 w-3 h-3 fill-current" /> Book a demo</a>
                            </div>
                        </li>

                    </ul>
                </div>
                <div onClick={() => setNav(!nav)} className='cursor-pointer inline-flex items-center p-2 ml-3 text-sm z-10 text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-gray-200  '>
                    {nav ? <FaTimes size={25} /> : <FaBars size={25} />}
                </div>
                {nav &&
                    <div className=" w-full text-center md:block md:w-auto lg:hidden" id="navbar-default">
                        <ul className="capitalize flex flex-col p-0 mt-0 bg-gray-50 rounded-lg border border-gray-100 ">
                            {links.map(({ id, link, title }) =>

                                <li key={id}>
                                    <Link to={link} smooth duration={500} onClick={() => setNav(!nav)} className="block py-2 pr-4 pl-3 border-b-[1px] border-gray-200 hover:bg-gray-100">{title}</Link>
                                </li>
                            )}
                        </ul>
                    </div>
                }
            </div>
        </nav >
    )
}

export default Navbar2
