import React from 'react'
import { FaCalendarAlt, FaPhoneAlt } from 'react-icons/fa'
import { Link } from 'react-scroll'
import HeroImg from '../xbot-hero.gif'


function HeroSlider() {
    return (
        <div name="top" className="grid md:grid-cols-3 py-10 ">
            <div className='text-center md:col-span-2 md:text-left ' >

                <h2 className='p-6 pb-2 text-3xl text-center font-medium md:pt-24 md:text-6xl md:text-left md:pl-36'>Grow your business with <span className='text-blue-800'> chat automation</span></h2>
                <p className='hidden md:block px-10 text-center md:pl-36 md:text-left text-gray-700 font-medium'>Lead generation, automate sales, marketing & customer support at one platform </p>
                <div className="flex justify-center md:justify-start">
                    <Link to="demo" className="leadforms-trigger-el inline-flex items-center mt-2 font-medium text-white bg-slate-900 md:ml-36 md:text-xl md:mt-6 focus:outline-none hover:bg-blue-800 focus:ring-4 focus:ring-blue-200 rounded-lg text-normal px-5 py-2.5 mr-2 mb-2 ">
                        <FaCalendarAlt className='w-4 h-4 mr-3 -ml-1' />Schedule a demo</Link>
                    <a href="tel:+919760341277" target="_blank" rel="noreferrer" type="button" className="inline-flex items-center mt-2 font-medium text-white bg-slate-900 md:ml-2 md:text-xl md:mt-6 focus:outline-none hover:bg-blue-800 focus:ring-4 focus:ring-blue-200 rounded-lg text-normal px-5 py-2.5 mr-2 mb-2 ">
                        <FaPhoneAlt className='w-4 h-4 mr-3 -ml-1' /> Call now</a>
                </div>
                <p className='hidden md:block px-12 text-center md:pl-36  md:text-left text-gray-700 font-medium'>Offering resolution focused automation </p>

            </div>
            <div className='flex justify-center '>
                <img src={HeroImg} alt="" className='h-[300px] md:h-[450px] pt-2 md:pt-2 w-auto ' />
            </div>
        </div>
    )
}

export default HeroSlider